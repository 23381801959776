import { clamp } from '../utils';
import SbsPlayerLogger from '../utils/logger';
import { SbsPlayerGlobal } from './global';


/**
 * PC 
 *
 * @export
 * @class Mouse
 */
export class Mouse {
  constructor(props) {
    this.$wrapper = SbsPlayerGlobal.view;
    // this.$ad = props.querySelector('sbs-player-ad-wrapper');
    // this.$player = props.querySelector('sbs-player-wrapper');
    this.props = props;
    this.init();
  }

  init() {
    if (SbsPlayerGlobal.isMobile) return false;

    const hideControls = (ms) => {
      try {
        const time = ms ? ms : SbsPlayerGlobal.options.hide_contorls_seconds;
        const adVolume = SbsPlayerGlobal.view.querySelector('sbs-player-ad-volume .controlBarWrap-volume');
        const volume = SbsPlayerGlobal.view.querySelector('sbs-player-volume .controlBarWrap-volume');

        SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
        clearTimeout(this.timoutEventHideController);

        this.timoutEventHideController = setTimeout(() => {
          adVolume?.setAttribute('aria-expanded', false);
          adVolume?.classList.remove('volumeSeek-open');
          volume?.setAttribute('aria-expanded', false);
          volume?.classList.remove('volumeSeek-open');
          SbsPlayerGlobal.events.emitEvent('controller-hide-event', true);
        }, time);
      } catch (error) {
        console.error('Error in hideControls:', error);
      }
    };

    hideControls();
    this.$wrapper.addEventListener('mousemove', () => {
      hideControls(1_500);
    });
    this.$wrapper.addEventListener('mouseout', () => {
      //hideControls(1_000);
    });
    this.$wrapper.addEventListener('mouseover', () => {
    });
  }
}
