import { SbsPlayerGlobal } from '../../../../common';
import { clamp, secondsToHms, secondsToText } from '../../../../utils';

import './AudioDesc';
import './Episode';
import './FullScreen';
import './LiveCaption';
import './LiveDivider';
import './LiveDvrButton';
import './LiveLabel';
import './PlayPauseReplay';
import './PlayPauseReplay/replay';
import './PopUp';
import './Preview';
import './Progress';
import './Qaulity';
import './Speed';
import './Subtitle';
import './Time';
import './Volume';
import './LiveTimeShift';

customElements.define('sbs-player-controls', class extends HTMLElement {
  constructor() {
    super();

    this.ended = false;
    this.panel = null;
    this.options = [];
    this.state = {
      dragging: false,
      quality_changed: false,
      turn_on_dvr: false,
      isFirst: true,
    }
  }

  static get observedAttributes() { return ['type', 'data']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  //TODO childmild ==>> 1. 속성이 allVod 일때 회차 버튼 활성화 띄워야함

  render() {
    try {
      // * 플레이어속성 또는 API에 의해 생성
      const { config, source, info } = SbsPlayerGlobal.state.data;
      const preview = source ? source.preview?.json : undefined;
      const subtitleButton = SbsPlayerGlobal.control.hiddens.includes('subtitle') ? false : source && source.subtitle;
      const audioDescButton = SbsPlayerGlobal.control.hiddens.includes('audiodesc') ? false : source && source.audio_description_url && info.type === 'VOD';
      const scrapButton = SbsPlayerGlobal.control.hiddens.includes('scrap') ? false : this.options.includes('scrap') || config?.control?.controlbar.button.scrap === 'Y';
      const episodeButton = SbsPlayerGlobal.control.hiddens.includes('episode') ? false : (!SbsPlayerGlobal.isMobile && SbsPlayerGlobal.vodtype.toLowerCase().indexOf('allvod') > -1) || (this.options.includes('episode'));
      const speedButton = SbsPlayerGlobal.control.hiddens.includes('speed') ? false : this.options.includes('speed') || (SbsPlayerGlobal.infoType !== 'onair');
      const dvrButton = SbsPlayerGlobal.control.hiddens.includes('dvr') ? false : this.options.includes('dvr') || (SbsPlayerGlobal.infoType === 'onair' && info.timeshift_yn === 'Y');
      const ccButton = SbsPlayerGlobal.control.hiddens.includes('cc') ? false : this.options.includes('cc') || (SbsPlayerGlobal.infoType === 'onair' && info.caption_yn === 'Y');
      const linkButton = SbsPlayerGlobal.control.hiddens.includes('link') ? false : this.options.includes('link') || config?.control?.controlbar.button.link === 'Y';
      const extendedButton = SbsPlayerGlobal.control.hiddens.includes('extended') ? false : this.options.includes('extended') || config?.control?.controlbar.button.extended === 'Y'; // 프로그램 템플릿 개편이 후 진행
      const popupButton = SbsPlayerGlobal.control.hiddens.includes('popup') || SbsPlayerGlobal.src ? false : (!episodeButton) || (this.options.includes('popup'));
      const fullscreenButton = SbsPlayerGlobal.control.hiddens.includes('fullscreen') ? false : this.options.includes('fullscreen') || config?.control?.controlbar.button.fullscreen === 'Y' || true;
      let liveDivider = SbsPlayerGlobal.infoType === 'onair' && !this.state.turn_on_dvr;
      let progressBar = SbsPlayerGlobal.infoType !== 'onair' || this.state.turn_on_dvr;

      //FIXME: For OMP
      if (SbsPlayerGlobal.src) {
        liveDivider = SbsPlayerGlobal.target === 'live' && SbsPlayerGlobal.src.length > 0;
        progressBar = !(SbsPlayerGlobal.target === 'live' && SbsPlayerGlobal.src.length > 0);
        if (SbsPlayerGlobal.target === 'live' && SbsPlayerGlobal.src.length > 0) SbsPlayerGlobal.view.querySelector('.playerWrap').classList.add('playerLiveType');

      }
      if (SbsPlayerGlobal.infoType === 'onair') {
        SbsPlayerGlobal.view.querySelector('.playerWrap').classList.add('playerLiveType');
      }

      this.innerHTML = `<div class="playerControls_w">
        ${liveDivider ? `<sbs-player-live-divider></sbs-player-live-divider>` : ``}
        <div class="playerSeekBar_w">
          ${preview ? `<sbs-player-preview></sbs-player-preview>` : ``}
          <sbs-player-progressbar style="visivility: ${progressBar ? 'visible' : 'hidden'}"></sbs-player-progressbar>
        </div>
        <div class="playerFunctionBtns">
          <div class="playerControls_left">
            ${!SbsPlayerGlobal.isMobile ? `<sbs-player-playpause></sbs-player-playpause>` : ``}
            ${!SbsPlayerGlobal.isMobile ? `<sbs-player-replay></sbs-player-replay>` : ``}
            ${!SbsPlayerGlobal.isMobile ? `<sbs-player-volume></sbs-player-volume>` : ``}
            ${SbsPlayerGlobal.isMobile && SbsPlayerGlobal.infoType == 'onair' ? `<sbs-player-live-label></sbs-player-live-label>` : ``}
            <sbs-player-time label="sbs-player-progressbar"></sbs-player-time>
          </div>
          <div class="playerControls_right">
            ${audioDescButton ? `<sbs-player-audio-desc-menu></sbs-player-audio-desc-menu>` : ``}
            ${subtitleButton ? `<sbs-player-subtitle-menu></sbs-player-subtitle-menu>` : ``}
            ${!SbsPlayerGlobal.isMobile || SbsPlayerGlobal.infoType == 'clip' ? `<sbs-player-quality-menu></sbs-player-quality-menu>` : ``}
            ${speedButton ? `<sbs-player-speed-menu></sbs-player-speed-menu>` : ``}
            ${!SbsPlayerGlobal.isMobile && dvrButton ? `<sbs-player-live-dvr></sbs-player-live-dvr>` : ``}
            ${ccButton ? `<sbs-player-live-caption></sbs-player-live-caption>` : ``}
            ${episodeButton ? `<sbs-player-episode></sbs-player-episode>` : ``}
            ${popupButton ? `<sbs-player-popup></sbs-player-popup>` : ``}
            ${fullscreenButton ? `<sbs-player-fullscreen></sbs-player-fullscreen>` : ``}
          </div>
        </div>
      </div>`;

      this.event();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      const { config, source, info } = SbsPlayerGlobal.state.data;

      const wrapperElement = SbsPlayerGlobal.view.querySelector('.playerWrap');
      const progress = this.querySelector('sbs-player-progressbar');
      const livedivider = this.querySelector('sbs-player-live-divider');
      const progressbar_wrapper = this.querySelector('.playerSeekBar_w');
      const playpause = this.querySelector('sbs-player-playpause');
      const replay = this.querySelector('sbs-player-replay');
      const volume = this.querySelector('sbs-player-volume');
      const time = this.querySelector('sbs-player-time');
      const livedvr = this.querySelector('sbs-player-live-dvr');
      const livecc = this.querySelector('sbs-player-live-caption');
      const fullscreen = this.querySelector('sbs-player-fullscreen');
      const audiodesc = this.querySelector('sbs-player-audio-desc-menu');
      const qualities = this.querySelector('sbs-player-quality-menu');
      const speed = this.querySelector('sbs-player-speed-menu');
      const subtitle = this.querySelector('sbs-player-subtitle-menu');
      const popup = this.querySelector('sbs-player-popup');
      const timeShift = this.querySelector('sbs-player-timeshift');

      const loadeddataToast = () => {
        const toastMessages = [];

        // * 이동통신망 toast
        if (navigation.connection && (navigation.connection.effectiveType === '3g' || navigation.connection.effectiveType === '4g')) {
          toastMessages.push('이동통신망(5G/LTE 등)으로 접속 시 과도한 데이터 통화료가 발생할 수 있습니다.');
          delete navigation.connection;
        }

        // * 이어보기 toast
        if (SbsPlayerGlobal.options.start) {
          toastMessages.push('마지막 시청 지점부터 이어서 재생됩니다.');
          delete SbsPlayerGlobal.options.start;
        }

        // * 방통위 toast
        if (!SbsPlayerGlobal.state.toast.isShowSubtitleNotice && source && (source.subtitle || source.audio_description_url) && source.toast.message) {
          toastMessages.push(source.toast.message.ko);
          SbsPlayerGlobal.state.toast.isShowSubtitleNotice = true;
          //delete source.toast.message;
        }

        SbsPlayerGlobal.view.querySelector('sbs-player-wrapper').toast(toastMessages);
      }

      timeShift?.addEventListener('timeshift-change-text', (event) => {
        this.dispatchEvent(new CustomEvent('timeshift-change-text', { detail: event.detail }));
      });

      qualities?.addEventListener('select-change-quality', (event) => {
        this.state.quality_changed = true;
        if (livecc) livecc.activated = false;
      });
      livedvr?.addEventListener('button-toggle-live-dvr', (event) => {
        livecc ? livecc.activated = false : false;
        livedivider ? livedivider.style.visivility = 'hidden' : false;
        this.state.turn_on_dvr = event.detail;

        if (event.detail) {
          wrapperElement.classList.add('timeMachineMode-on');
          progressbar_wrapper.style.visivility = 'visible';
        } else {
          wrapperElement.classList.remove('timeMachineMode-on');
          progressbar_wrapper.style.visivility = 'hidden';
        }
      });
      livecc?.addEventListener('button-toggle-live-caption', (event) => {
        livedvr ? livedvr.activated = false : false;
        livedivider ? livedivider.style.visivility = 'visible' : false;
        progressbar_wrapper.style.visivility = 'hidden';
        wrapperElement.classList.remove('timeMachineMode-on');

        if (qualities) qualities.style.display = event.detail ? 'block' : 'none';
      });
      progress?.addEventListener('progress-is-dragging', (event) => {
        this.state.dragging = event.detail;
      });
      SbsPlayerGlobal.events.addEventListener('hls-disposed', (event) => {
        console.log('hls-disposed');
        if (playpause) playpause.paused = true;
        if (playpause) playpause.visible = true;
      });

      SbsPlayerGlobal.events.addEventListener('video-change-player', (event) => {
        try {
          const video = event.target;
          if ('timeupdate' === event.type && progress && video.currentTime && video.duration) {
            if (!this.state.dragging) {
              progress.current = video.currentTime / video.duration;
            }
            let timeHHMMSS = `${secondsToHms(video.currentTime)} / ${secondsToHms(video.duration)}`;
            time.current = video.currentTime;
            time.currentText(time.current);
            //time.text(timeHHMMSS);

            this.labelTime(video, progressbar_wrapper);
          }
          if ('progress' === event.type && progress && video.duration) {
            //progress.progress = video.buffered?.end(0) / video.duration;
          }
          if ('volumechange' === event.type && volume) {
            let value = clamp(video.volume * 100, 0, 100);
            //value = Math.round(value / 10) * 10; // 10분위로 반올림
            volume.vol = value;
            if (volume.vol > 0) SbsPlayerGlobal.options.muted = false;
          }

          if ('canplay' === event.type) {
          }

          if ('waiting' === event.type) {
          }

          if ('durationchange' === event.type && progressbar_wrapper && time) {
            if (SbsPlayerGlobal.infoType !== 'onair') {
              progressbar_wrapper.setAttribute('aria-valuemin', 0);
              progressbar_wrapper.setAttribute('aria-valuemax', Math.round(video.duration / 10));
            }
            time.duration = video.duration;
            time.render();
          }

          if ('pause' === event.type && SbsPlayerGlobal.infoType !== 'onair') {
            if (playpause) playpause.paused = true;
          }

          if ('ended' === event.type) {
            if (playpause) playpause.visible = false;
            if (replay) replay.visible = true;
          }

          if ('play' === event.type) {
            if (playpause) playpause.paused = false;
            if (playpause) playpause.visible = true;
            if (replay) replay.visible = false;
            // loadeddata 이벤트에 걸 경우 광고로 인해 안보임
            if (SbsPlayerGlobal.infoType !== 'onair') loadeddataToast();

            //FIXME: For OMP
            if (SbsPlayerGlobal.src && this.state.isFirst) {
              volume.vol = 0.5;
              this.state.isFirst = false;
            }
          }

          if ('loadeddata' === event.type) {
            // loadeddataToast();
          }

          if ('emptied' === event.type) {
          }
        } catch (error) {
          console.log(error);
        }
      });

      const elements = [
        { element: progress, event: 'progress-click' },
        { element: playpause, event: 'button-toggle-playpause' },
        { element: replay, event: 'button-click-replay' },
        { element: popup, event: 'button-click-popup' },
        { element: volume, event: 'button-toggle-volume' },
        { element: volume, event: 'input-change-volume' },
        { element: livedvr, event: 'button-toggle-live-dvr' },
        { element: livecc, event: 'button-toggle-live-caption' },
        { element: fullscreen, event: 'button-toggle-fullscreen' },
        { element: subtitle, event: 'button-toggle-subtitle' },
        { element: subtitle, event: 'subtitle-size-change' },
        { element: qualities, event: 'select-change-quality' },
        { element: audiodesc, event: 'button-toggle-audiodesc' }
      ];

      for (const { element, event } of elements) {
        if (!element) continue;
        element.addEventListener(event, (e) => {
          if (event !== 'subtitle-size-change') {
            this.closeMenus();
          }
          this.dispatchEvent(new CustomEvent(event, { detail: e.detail }));
        });
      }

      const menus = [audiodesc, qualities, speed, subtitle];
      menus.forEach(menu => {
        if (!menu) return;
        menu.addEventListener('control-menu-toggle', event => {
          this.closeMenus();

          this.panel = event.detail ? event.target : null;
          menu.opened = event.detail;

          const wrapperElement = SbsPlayerGlobal.view.querySelector('.playerWrap.playerMobileType');

          if (menu.opened) {
            // * caption_disable(button title)
            this.querySelector('.playerFunctionBtns').classList.add('caption_disable');
            wrapperElement?.classList.add('mobileLayerType');
          } else {
            this.querySelector('.playerFunctionBtns').classList.remove('caption_disable');
            wrapperElement?.classList.remove('mobileLayerType')
          }

        });
      });

      SbsPlayerGlobal.events.addEventListener('controller-hide-event', (hidden) => {
        const networkAdView = SbsPlayerGlobal.view.querySelector('.playerADVideoCont iframe');
        const mobileQualityMenuLayer = SbsPlayerGlobal.view.querySelector('.playerWrap.playerMobileType .selectLayerWrap-quality');
        const hideControls = this.querySelector('.playerControls_w');
        const mobileMotionAnimation = SbsPlayerGlobal.view.querySelector('.MobileMotionMoving .current_animation');

        // * 모바일 모션 동작시
        if (mobileMotionAnimation) return false;

        // * 예외처리, 모바일에서 화질변경 메뉴 활성화시엔 콘트롤러를 숨지기 않는다.
        if (hidden && (!mobileQualityMenuLayer || mobileQualityMenuLayer.style.display === 'none')) {
          hideControls.classList.add('hide_controls');
          SbsPlayerGlobal.view.querySelector('.playerWrap').classList.add('bottomDim-hide');
          this.closeMenus();
        } else {
          hideControls.classList.remove('hide_controls');
          if (!networkAdView) SbsPlayerGlobal.view.querySelector('.playerWrap').classList.remove('bottomDim-hide');
        }
      });

    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      // * 없애는건 아니다. 그냥 라인은 디자인 때문에 필요하다. -> 기존자원을 그대로쓰기엔 웹접근성 때문에 어려울듯. 별도로 마크업 요청
      const progressbar_wrapper = this.querySelector('.playerSeekBar_w');
      // if (SbsPlayerGlobal.infoType === 'onair') {
      //   progressbar_wrapper.style.visibility = 'hidden';
      // }
    } catch (error) {
      console.log(error);
    }
  }

  closeMenus() {
    try {
      // * 메뉴패널 닫을때 모바일 예외처리(이렇게 말고 playerMobileType 상태일 경우, 메뉴 닫지 않기?
      const mobileLayerElement = SbsPlayerGlobal.view.querySelector('.mobileLayerType');
      mobileLayerElement?.classList.remove('mobileLayerType')

      const audiodesc = this.querySelector('sbs-player-audio-desc-menu');
      const qualities = this.querySelector('sbs-player-quality-menu');
      const speed = this.querySelector('sbs-player-speed-menu');
      const subtitle = this.querySelector('sbs-player-subtitle-menu');
      if (audiodesc) audiodesc.opened = false;
      if (qualities) qualities.opened = false;
      if (speed) speed.opened = false;
      if (subtitle) subtitle.opened = false;
      this.panel = null;

    } catch (error) {
      console.log(error);
    }

  }

  ended() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  labelTime(video, element) {
    try {

      let timeText = SbsPlayerGlobal.infoType !== 'onar' ? `${secondsToText(video.currentTime)} / ${secondsToText(video.duration)}` : '라이브 재생 중';
      // 너무자주호출되어서 지장이 있어선 안됨..
      element.setAttribute('aria-valuenow', Math.round(video.currentTime));
      element.setAttribute('aria-valuetext', timeText);
    } catch (error) {
      console.log(error);
    }
  }
});