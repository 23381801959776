//import noticeIcon from '../../../../../../assets/controller_notice.svg';
import { SbsPlayerGlobal } from '../../../../../common';
import { clamp } from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';
import { loadLocalStorage } from '../../../../../utils/storage';

/**
 * 플레이어 화면 구현
 */
customElements.define('sbs-player-subtitle-menu', class extends HTMLElement {
  constructor() {
    super();
    this.size = 100;
    this.activated = false;
    this.opened = false;
    this.info_opened = false;
    this.panel_id = `sbs-player-subtitle-panel-${SbsPlayerGlobal.id.uuid}`;
    this.menuitem_id = `sbs-player-subtitle-menuitem-${SbsPlayerGlobal.id.uuid}`;
    this.toggle_id = `sbs-player-subtitle-toggle-${SbsPlayerGlobal.id.uuid}`;
    this.toggle_on = `sbs-player-subtitle-toggle-on-${SbsPlayerGlobal.id.uuid}`;
    this.toggle_off = `sbs-player-subtitle-toggle-off-${SbsPlayerGlobal.id.uuid}`;

  }

  set activated(value) {
    this._activated = value;
    this.render();
  }
  get activated() {
    return this._activated;
  }

  set opened(value) {
    this._opened = value;
    this.render();
  }
  get opened() {
    return this._opened;
  }

  connectedCallback() {
    try {
      this.render();

      if (loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_subtitle) === 'true') {
        this.activated = true;
        setTimeout(() => this.dispatchEvent(new CustomEvent('button-toggle-subtitle', { detail: true })), 1_000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (SbsPlayerGlobal.infoType && SbsPlayerGlobal.infoType.indexOf('onair') > -1) {
        return;
      }
      const { source } = SbsPlayerGlobal.state.data;
      const message = document.documentElement.lang === 'en' ? source.toast.message.en : source.toast.message.ko;

      this.innerHTML = `<div id="${this.panel_id}" class="controlSelect-subtitle ${this.opened ? 'open-layer' : ''}">
          <button id="${this.menuitem_id}" type="button" 
          aria-haspopup="true"
          aria-expanded="${this.opened ? 'true' : 'false'}" class="btnText-subtitle ${this.activated ? 'current' : ''}">
            <span class="text-name">자막</span>
          </button>
          <div class="selectLayerWrap-subtitle" style="display: ${this.opened ? 'block' : 'none'}">
            <div class="subtitleSizeControl_w">
              <strong class="layerText-subtit">자막크기</strong>
              <span class="layerText-number">${this.size}%</span>
              <div class="sizeControlBtns">
                <button type="button" class="btnSizeControl-down">
                  <span class="iconSvg_down"><i class="hide">자막 작게</i></span>
                </button>
                <button type="button" class="btnSizeControl-up">
                  <span class="iconSvg_up"><i class="hide">자막 크게</i></span>
                </button>
              </div>
            </div>
            <button id="${this.toggle_id}" type="button" class="btnLayerSelect-subtitle ${this.activated ? 'current' : ''}" value="${this.activated}">
              <strong class="layerText-subtit">자막</strong>
              <span class="layerText-subtext">${this.activated ? 'ON' : 'OFF'}</span>
            </button>
            <button type="button" class="btnInfo-subtitle" aria-haspopup="true">자막방송 지원<i class="iconSvg_notice"></i>
            </button>
            ${this.info_opened ? `<div class="infoLayerWrap-subtitle ${this.info_opened ? 'infoLayer-open' : ''}">
              <p class="infoLayer-text">${message}</p>
              <button type="button" class="btnLayer-closed">
                <span class="hide">닫기</span>
              </button>
            </div>` : ``}
            <div class="selectMobileType-subtitle">
              <button id="${this.toggle_on}" type="button" class="btnLayerSelect-subtitle ${this.activated ? 'current' : ''}">ON</button>
              <button id="${this.toggle_off}" type="button" class="btnLayerSelect-subtitle ${this.activated ? '' : 'current'}">OFF</button>
            </div>
          </div>
        </div>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }


  event() {
    try {
      this.querySelector(`#${this.menuitem_id}`)?.addEventListener('click', (event) => {
        event.preventDefault();
        this.opened = !this.opened;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));
      });

      // * PC
      this.querySelector(`#${this.toggle_id}`)?.addEventListener('click', (event) => {
        this.activated = !this.activated;
        this.opened = false;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));
        this.dispatchEvent(new CustomEvent('button-toggle-subtitle', { detail: this.activated }));
        this.render();
      });

      // * Mobile
      this.querySelector(`#${this.toggle_on}`)?.addEventListener('click', (event) => {
        this.activated = true;
        this.opened = false;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));
        this.dispatchEvent(new CustomEvent('button-toggle-subtitle', { detail: this.activated }));
        this.render();
      });
      // * Mobile
      this.querySelector(`#${this.toggle_off}`)?.addEventListener('click', (event) => {
        this.activated = false;
        this.opened = false;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));
        this.dispatchEvent(new CustomEvent('button-toggle-subtitle', { detail: this.activated }));
        this.render();
      });

      this.querySelector('.btnInfo-subtitle')?.addEventListener('click', (event) => {
        this.info_opened = true;
        this.render();
      });

      this.querySelector('.btnLayer-closed')?.addEventListener('click', (event) => {
        this.info_opened = false;
        this.render();
      });

      this.querySelector('.btnSizeControl-down')?.addEventListener('click', () => {
        this.size = clamp(this.size - 10, 80, 200);
        this.dispatchEvent(new CustomEvent('subtitle-size-change', { detail: this.size }));
        this.render();
      });
      this.querySelector('.btnSizeControl-up')?.addEventListener('click', () => {
        this.size = clamp(this.size + 10, 80, 200);
        this.dispatchEvent(new CustomEvent('subtitle-size-change', { detail: this.size }));
        this.render();

      });
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
});