import { SbsPlayerGlobal } from '../common';
import { loadCookie } from './cookie';
import { isAndroid, isIOS } from './navigator';

export function getAppLink(channelId, payType, playType, rscUse) {
  var getObjectToUriParameter = (data) => {
    var uriParameter = '';
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        uriParameter += key + '=' + data[key] + '&';
      }
    }
    if (uriParameter.length > 0) {
      uriParameter = uriParameter.substring(0, uriParameter.length - 1);
    }
    return uriParameter;

  }

  let url = '';
  let params = '';
  let token = '';
  let scheme = '';

  channelId = channelId.trim();
  payType = (payType === 'Y') ? 'CB' : 'FR';
  if (loadCookie('LOGIN_JWT')) {
    token = loadCookie('LOGIN_JWT');
  }

  // * 참고 - VOD 페이지의 경우는 웹페이지 영상 권한 정보를 앱 엔드에 전달해주어 웹에서 S권한 로그인 이용자의 경우 앱이 비로그인 상태여도 풀 VOD 재생이 가능함
  if (playType === 'live' || playType === 'onair') {
    // sbsplayer://onair?token={value}&channel={value}&mode={value}&plink={value}
    playType = 'onair'
    let appParameter = {
      channel: 'S01',
      token: token,
      plink: '',
      returnUrl: encodeURIComponent(location.href)
    };
    params = `${playType}?${getObjectToUriParameter(appParameter)}#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end`;
    scheme = 'sbsplayer';
  } else {
    const { info, login_info } = SbsPlayerGlobal.state.data;

    // * mobileweb 일 경우 VOD3MIN 으로 고정
    playType = playType.toUpperCase() === 'VOD3MIN' && info && login_info && (login_info.is_s_member || info.free) ? 'VOD' : playType;
    playType = playType || info.type;

    let appParameter = {
      cid: info.mediaid,
      type: ((playType.toUpperCase() === 'VOD' || playType.toUpperCase() === 'VOD3MIN') && !info.free) ? info.type : playType,
      flag1: 'app',
      flag2: '',
      platform: 'mobileapp',
      device: 'app',
      token: token,
      plink: '',
      returnUrl: encodeURIComponent(location.href)
    };
    if (info.free) {
      appParameter.v_type = '2'
    }

    // ? android: `intent://clip?cid=${encodeURIComponent(mediaId)}&flag1=app&rscuse=${quality}&#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end`,
    // ? ios: `sbsplayer://clip?cid=${encodeURIComponent(mediaId)}&flag1=app&rscuse=${quality}&returnUrl=${window.location.href}`

    // ? 'intent://vod?cid=22000367829&type=VOD3MIN&flag1=app&flag2=&rscuse=&platform=mobileapp&device=app&jwt-token=&plink=&returnUrl=http%3A%2F%2Fm.programs.cloud.sbs.co.kr%2Fspecial%2Ftest20170804%2Fvod%2F55626%2F22000367829#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end'
    // ? 'intent://vod?cid=22000367829&type=vod&flag1=app&flag2=&rscuse=05&platform=mobileapp&device=app&token=&plink=&returnUrl=http%3A%2F%2Fm.programs.cloud.sbs.co.kr%2Fspecial%2Ftest20170804%2Fvod%2F55626%2F22000367829&v_type=2#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end'

    params = `${playType}?${getObjectToUriParameter(appParameter)}#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end`;
    scheme = 'sbsplayer';
  }

  if (isAndroid()) {
    url = 'intent://' + params + `#Intent;scheme=${scheme};package=kr.co.sbs.videoplayer;end`;
  } else if (isIOS()) {
    url = `${scheme}://` + params;
  }

  return url;
};
