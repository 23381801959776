import { SbsPlayerGlobal } from '../../../common';

class LiveMidroll {
  constructor() {
    this.id3 = {};
    this.last = {};

    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      needed: (slots, id3) => {
        console.log(`needed. ${slots}, ${JSON.stringify(id3)}`);
      },
      adStarted: (type, duration, title) => {
        console.log(`adStarted. ${type}, ${duration}, ${title}`);
      },
      adEnded: (type) => {
        console.log(`adEnded. ${type}`);
      },
    };
  }

  set event(event) {
    this._event = event;
  }

  get event() {
    return this._event;
  }

  init = (player, video) => {
    try {
      SbsPlayerGlobal.live_midroll.rd_queue = [];
      SbsPlayerGlobal.live_midroll.ct_queue = [];

      this.video = video;
      player.on(Hls.Events.FRAG_LOADED, (event, data) => {
      });
      player.on(Hls.Events.FRAG_PARSING_METADATA, (event, data) => {
        this.parse(data);
      });
      video.addEventListener('pause', () => {
        this.reset();
      });
      video.addEventListener('loadeddata', () => {
        this.reset();
      });
    } catch (error) {
      console.log(error);
    }
  }

  parse = (data) => {
    try {
      if (this.video.paused) {
        //console.warn(`live midroll parse video paused`);
        return false;
      }
      const video_pts = this.video.currentTime;
      // If sync PTS is less than zero, we have a 33-bit wraparound, which is fixed by adding 2^33 = 8589934592.
      const pts_diff = 8589934592 / 90000;

      data.samples.forEach(sample => {
        try {
          const sample_pts = sample.pts;
          while (sample_pts <= video_pts) sample_pts += pts_diff;
          const id3_offset = (sample_pts - video_pts) * 1000;
          const id3_str = String.fromCharCode.apply(null, sample.data);
          const start = id3_str.lastIndexOf("{");
          const end = id3_str.indexOf("}", start + 1);

          // * 최초 진입시에 
          if (Object.keys(this.id3).length === 0) {
            this.id3 = JSON.parse(id3_str.substring(start, end + 1));
            this.start(this.id3);
          } else {
            this.id3 = JSON.parse(id3_str.substring(start, end + 1));
            this.process(id3_offset);
            this.last = this.id3;
          }
          SbsPlayerGlobal.live_midroll.id3 = this.id3;
          console.log(JSON.stringify(this.id3));

        } catch (error) {
          console.error('FRAG_PARSING_METADATA: ' + error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  enQueue = (type, queue) => {
    if (type === 'ct') {
      // 연동형
      SbsPlayerGlobal.live_midroll.ct_queue = SbsPlayerGlobal.live_midroll.ct_queue.concat(queue);
      clearTimeout(SbsPlayerGlobal.live_midroll.ct_request_timeout);
    } else {
      // 독립형
      SbsPlayerGlobal.live_midroll.rd_queue = SbsPlayerGlobal.live_midroll.rd_queue.concat(queue);
      clearTimeout(SbsPlayerGlobal.live_midroll.rd_request_timeout);
    }

    console.log(type === 'ct' ? '연동' : '독립', '형 큐 충전완료', SbsPlayerGlobal.live_midroll.rd_queue.length, '|', SbsPlayerGlobal.live_midroll.ct_queue.length);
    console.log('sectionType', this.id3.sectionType);

    this._event.filled();
  }

  deQueue = (type) => {
    try {
      const { groupCount, delaySec, random_targeting } = SbsPlayerGlobal.state.data.ad_30.midroll;

      const queue = type === 'MID_CM' && SbsPlayerGlobal.live_midroll.ct_queue.length ? SbsPlayerGlobal.live_midroll.ct_queue : SbsPlayerGlobal.live_midroll.rd_queue;
      const ad = queue.shift();

      // * SMR p11) addSize
      //console.log(`deQueue (${SbsPlayerGlobal.live_midroll.rd_queue.length}/${SbsPlayerGlobal.live_midroll.ct_queue.length})`);
      if (SbsPlayerGlobal.live_midroll.rd_queue.length <= random_targeting.minsize) {
        const rdMagicNumber = this.getMagicNumber(groupCount, delaySec) * 1_000;
        console.log('독립형 큐 최소 요청', random_targeting.minsize, parseInt(rdMagicNumber / 1000), '(s)');
        if (!SbsPlayerGlobal.live_midroll.rd_request_timeout) {
          clearTimeout(SbsPlayerGlobal.live_midroll.rd_request_timeout);
          SbsPlayerGlobal.live_midroll.rd_request_timeout = setTimeout(() => {
            if (SbsPlayerGlobal.live_midroll.rd_queue.length <= random_targeting.minsize) {
              console.log('독립형 큐 요청 (+)', random_targeting.addsize);
              this._event.needed(random_targeting.addsize);
            }
            SbsPlayerGlobal.live_midroll.rd_request_timeout = null;
          }, rdMagicNumber);
        } else {
          console.log('독립형 분산대기로 요청취소');
        }
      }
      return ad;
    } catch (error) {
      console.log(error);
    }
  }

  start = (id3) => {
    try {
      const { groupCount, delaySec, random_targeting, content_targeting } = SbsPlayerGlobal.state.data.ad_30.midroll;
      const rdMagicNumber = this.getMagicNumber(groupCount, delaySec);

      console.log(`<서비스 진입> (${id3.sectionType})`);
      console.log(`독립형 큐 입력 요청(*분산처리) 분산 delay: ${rdMagicNumber}(s) 뒤$(${new Date(new Date().getTime() + (rdMagicNumber * 1000)).toLocaleTimeString()})에 독립형 광고 호출 예정`);
      clearTimeout(SbsPlayerGlobal.live_midroll.rd_request_timeout);
      SbsPlayerGlobal.live_midroll.rd_request_timeout = setTimeout(() => {
        this._event.needed(random_targeting.maxsize);
        SbsPlayerGlobal.live_midroll.rd_request_timeout = null;
      }, ((rdMagicNumber) * 1000));

      if (id3.sectionType === 'MID_CM') {
        const ctMagicNumber = this.getMagicNumber(groupCount, delaySec);
        console.log(`연동형 큐 요청(*분산처리) 분산 delay: ${ctMagicNumber}(s) 뒤(${new Date(new Date().getTime() + (ctMagicNumber * 1000)).toLocaleTimeString()})에 연동형 광고 호출 예정`);
        clearTimeout(SbsPlayerGlobal.live_midroll.ct_request_timeout);
        SbsPlayerGlobal.live_midroll.ct_request_timeout = setTimeout(() => {
          this._event.needed(content_targeting.maxsize, this.id3);
        }, (ctMagicNumber * 1000));
      }
    } catch (error) {
      console.log(error);
    }
  }

  process = (id3_offset) => {
    try {
      const { groupCount, delaySec, random_targeting, content_targeting } = SbsPlayerGlobal.state.data.ad_30.midroll;

      if (this.id3.sectionType === undefined) {
        console.warn('id3 sectionType is undefined');
        return;
      }
      // 1.2.3 단계 별 고려 사항
      if (this.id3.sectionType === 'CANCEL') {
        console.warn('id3 sectionType is CANCEL');
        this._event.adEnded(this.id3.sectionType);
        return;
      }
      // * SMR p3) type0... 일 경우 용어정리가..
      // * Type 0 : 광고 start time 값과 stop time 값이 없는 경우
      // * Type 1 : 광고 start time 값과 stop time 값이 있을 경우
      // * Type 2 : 광고 start time 값이 있고, stop time 값이 없을 경우
      // * Type 3 : 광고 start time 값이 없고, stop time 값이 있을 경우

      if (this.id3.startTime === undefined && this.id3.stopTime === undefined) {
        //console.warn('id3 type 0');
        return;
      }

      if (this.last.startTime !== this.id3.startTime && this.last.stopTime !== this.id3.stopTime) {
        if (this.id3.programId && this.last.programId !== this.id3.programId) {
          console.info('update id3 programId', this.id3.programId);
          console.info('<4/6/11. 방송편성 연동형 큐 초기화>');
          SbsPlayerGlobal.live_midroll.ct_queue = [];
          if (this.id3.sectionType === 'MID_CM') {
            const ctMagicNumber = this.getMagicNumber(groupCount, delaySec);
            if (!SbsPlayerGlobal.live_midroll.ct_request_timeout) {
              SbsPlayerGlobal.live_midroll.ct_request_timeout = setTimeout(() => {
                this._event.needed(content_targeting.maxsize, this.id3);
              }, ((ctMagicNumber) * 1000));
            }

          }
        }

        if (this.id3.programName && this.last.programName !== this.id3.programName) {
          console.info('update id3 programName', this.id3.programName);
        }

        if (this.id3.section && this.last.section !== this.id3.section) {
          console.info('update id3 section', this.id3.section);
        }

        if (this.id3.sectionType && this.last.sectionType !== this.id3.sectionType) {
          console.info('update id3 sectionType', this.id3.sectionType);
        }

        let startTime = Date.parse(this.id3.startTime);
        let stopTime = Date.parse(this.id3.stopTime);
        let mediaTime = Date.parse(this.id3.mediaTime);
        let delay = startTime - mediaTime + id3_offset;

        /*
        * • mediaTime은 ID3 tag가 들어가 있는 시각을 나타냄
          • 현재 재생중인 시각 P = mediaTime – id3_offset
          • 광고 시작까지 남은 시간 delay(ms) = startTime – P
          • = startTime – mediaTime + id3_offset
          • = id3_offset – 8
          • = video.currentTime – data.samples[0].pts – 8
          • 광고 길이 duration(ms) = stopTime – startTime = 30000 (ms)
          • 현재 시각 P로 부터 delay(ms) 초 후에, duration(ms) 초 만큼 광고를 대체
        * */

        // startAdSequenceTime 보다 delay로 광고시작..
        let startAdSequenceTime = Math.abs((mediaTime - id3_offset) - startTime);
        let stopAdTime = Math.abs((mediaTime - id3_offset) - stopTime);
        let durationAdTime = Math.abs(stopTime - startTime);
        let pTime = (mediaTime - id3_offset);
        console.info(`================================================================`)
        console.info('중간광고');
        console.info('시작 시 : ' + new Date(startTime).toLocaleString());
        console.info('종료 시 : ' + new Date(stopTime).toLocaleString());
        console.info('delay(s) : ' + (delay / 1000));
        console.info('duration(s) : ' + (durationAdTime / 1000));

        if (!isNaN(delay)) {
          // 자동편성 type 1,2
          if (delay < 0) {
            // 뒤늦게 태그를 받았을 경우..
            durationAdTime = Math.abs(stopTime - (pTime)); // 남은 시간만큼 재생
            delay = 500;
            console.info(`대체광고를 시작을 놓친 케이스, 0.5(s) 초뒤 남은 시간 (${(durationAdTime / 1000)}) 만큼 광고재생`);
          }

          if (!isNaN(durationAdTime)) {
            console.info(`[대체광고] 자동편성, type1, ${this.id3.sectionType}, ${(delay / 1000)}(s) 후 광고시작 예정, 총 광고길이: ${(durationAdTime / 1000)} (s)`);
            console.info(`${new Date(new Date().getTime() + (delay * 1000)).toLocaleTimeString()} 광고재생 예정`);
          } else {
            console.info(`[대체광고] 수동편성, type2, ${this.id3.sectionType}, ${(delay / 1000)}(s) 후 광고시작 예정`);
          }

          clearTimeout(SbsPlayerGlobal.live_midroll.start_timeout);
          SbsPlayerGlobal.live_midroll.start_timeout = setTimeout(() => {


            this.startedoverlay(this.id3.sectionType, durationAdTime, decodeURIComponent(this.id3.programName));

            if (!isNaN(durationAdTime)) {
              console.log(`[대체광고] 자동편성, type1, ${this.id3.sectionType}, ${(durationAdTime / 1000)}(s) 뒤 광고종료 예정`);
              clearTimeout(SbsPlayerGlobal.live_midroll.end_timeout);
              SbsPlayerGlobal.live_midroll.end_timeout = setTimeout(() => {
                console.log(`[대체광고] 자동편성, type1, ${this.id3.sectionType} 광고종료!`);
                SbsPlayerGlobal.live_midroll.end_timeout = null;
                clearInterval(SbsPlayerGlobal.live_midroll.end_timeout);
                clearInterval(SbsPlayerGlobal.live_midroll.timer_interval);
                this.stopedoverlay(this.id3.sectionType);
              }, durationAdTime);

              SbsPlayerGlobal.live_midroll.interval = 0;
              clearInterval(SbsPlayerGlobal.live_midroll.timer_interval);
              SbsPlayerGlobal.live_midroll.timer_interval = setInterval(() => {
                console.log(`[대체광고] 자동편성1 ${SbsPlayerGlobal.live_midroll.interval++}/${parseInt(durationAdTime / 1_000)} ${decodeURIComponent(this.id3.programName)}`);
                const adLveInfo = SbsPlayerGlobal.view.querySelector('sbs-player-ad-info-live');
                adLveInfo?.render(parseInt(durationAdTime / 1_000 - SbsPlayerGlobal.live_midroll.interval));
              }, 1_000);
            }
          }, delay);
        } else {
          // 수동편성
          // delay undefined (스타트가 없는경우)
          // 수동편성 처음
          // type 3
          if (!isNaN(stopAdTime)) {
            clearTimeout(SbsPlayerGlobal.live_midroll.end_timeout);
            console.info(`[대체광고] 수동편성, type3, ${this.id3.sectionType}, ${(stopAdTime / 1_000)}(s) 뒤 광고종료 예정`);
            SbsPlayerGlobal.live_midroll.end_timeout = setTimeout(() => {
              console.info(`[대체광고] 수동편성, type3, ${this.id3.sectionType} 광고종료!`);
              SbsPlayerGlobal.live_midroll.end_timeout = null;
              clearInterval(SbsPlayerGlobal.live_midroll.end_timeout);
              clearInterval(SbsPlayerGlobal.live_midroll.timer_interval);
              this.stopedoverlay(this.id3.sectionType);
            }, stopAdTime);

            SbsPlayerGlobal.live_midroll.interval = 0;
            clearInterval(SbsPlayerGlobal.live_midroll.timer_interval);
            SbsPlayerGlobal.live_midroll.timer_interval = setInterval(() => {
              console.log(`[대체광고] 자동편성3 ${SbsPlayerGlobal.live_midroll.interval++}/${parseInt(stopAdTime / 1_000)} ${decodeURIComponent(this.id3.programName)}`);
              SbsPlayerGlobal.view.querySelector('sbs-player-ad-info-live').render(parseInt(stopAdTime / 1_000 - SbsPlayerGlobal.live_midroll.interval));
            }, 1_000);
          }
        }
      } else {
        //console.warn(`동일 id3`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  startedoverlay = (sectionType, duration, title) => {
    try {
      SbsPlayerGlobal.live_midroll.duration = duration;
      SbsPlayerGlobal.live_midroll.title = title;

      console.log(`${sectionType}, ${duration}`);
      if (!duration || duration < 30000) {
        console.warn(`광고구간 길이가 설정되지 않거나, 광고구간의 길이(${(duration / 1000)})가 30초 이내일 경우 방송편성 연동형/독립형 Queue 노출을 하지 않아야 함`);
        return;
      }
      this._event.adStarted(sectionType, parseInt(duration / 1000), title);
    } catch (error) {
      console.error(error);
    }
  }

  stopedoverlay = (sectionType) => {
    try {
      SbsPlayerGlobal.live_midroll.duration = null;
      SbsPlayerGlobal.live_midroll.title = null;
      // if (sectionType === 'POST_CM') {
      //   console.info('<4/6/11. 방송편성 연동형 큐 초기화>');
      //   SbsPlayerGlobal.live_midroll.ct_queue = [];
      // }
      this.reset();
      this._event.adEnded(sectionType);
    } catch (error) {
      console.log(error);
    }
  }

  reset = () => {
    try {
      console.log('reset');

      clearTimeout(SbsPlayerGlobal.live_midroll.rd_request_timeout);
      clearTimeout(SbsPlayerGlobal.live_midroll.ct_request_timeout);
      clearTimeout(SbsPlayerGlobal.live_midroll.start_timeout);
      clearTimeout(SbsPlayerGlobal.live_midroll.end_timeout);

      SbsPlayerGlobal.live_midroll.rd_request_timeout = null;
      SbsPlayerGlobal.live_midroll.ct_request_timeout = null;
      SbsPlayerGlobal.live_midroll.start_timeout = null;
      SbsPlayerGlobal.live_midroll.end_timeout = null;

      SbsPlayerGlobal.live_midroll.rd_queue = [];
      SbsPlayerGlobal.live_midroll.ct_queue = [];

      this.id3 = {};
      this.last = {};
    } catch (error) {
      console.error(error);
    }
  }

  getMagicNumber = (groupCount, delaySec) => {
    const myCount = Math.floor(Math.random() * (groupCount - 1)) + 1;
    return myCount * delaySec;
  }
}

export default LiveMidroll;