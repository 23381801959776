import { SbsPlayerGlobal } from '../../common';
import { toFormattedDate } from '../../utils';
import { getTID, getUUID } from '../../utils/advertisements';
import SbsPlayerLogger from '../../utils/logger';

class SmrMediaLog {
  constructor() {
    this.state = {
      send: {
        start: false,
        first: false,
        mid: false,
        third: false,
        end: false,
        retry: 0
      }
    };
    this.payload = {};
  }

  getPayload() {
    try {
      const { ad, ad_25, ad_30, log_15, log_20 } = SbsPlayerGlobal.state.data;
      const smrAdVersion = (ad.smr_ad_version || '2.5').replace(/\./g, "");
      const ad_smr = this.live || smrAdVersion === '30' ? ad_30 : ad_25;
      const smrMediaLogVersion = (ad.smr_media_log_version || '2.0').replace(/\./g, "");

      const log_smr = smrMediaLogVersion === '20' ? log_20 : log_15;

      const payload = {
        meta: {
          relayversion: 'V10',
          platform: SbsPlayerGlobal.isMobile ? 'MOBILEWEB' : 'PCWEB',
          playertype: 'HTML5',
          service: SbsPlayerGlobal.infoType.toLowerCase(),
          requesttime: toFormattedDate(new Date(), 'yyyyMMddHHmmss'),
          enctype: 'JWT',
          target: 'SMR',
          usetarget: true
        },
        data: {
          targetversion: log_smr.version,
          api: 'http://medialog.smartmediarep.com/api/v20/tracking/tracking.sb',
          log: {
            medialog: encodeURIComponent(JSON.stringify({
              version: log_smr.version,
              media: log_smr.media,
              site: log_smr.site,
              tid: getUUID(ad_smr),
              uuid: getTID(ad_smr),
              ip: log_smr.ip,
              //trackpoint: command.counter === 0 ? '0' : `${command.counter}/4`,
              gender: log_smr.gender,
              age: log_smr.age,
              platform: SbsPlayerGlobal.isMobile ? 'MOBILEWEB' : 'PCWEB',
              playertype: 'HTML5',
              os: '',
              devicemodel: '',
              telco: '',
              referrer: log_smr.referrer,
              cpid: log_smr.cpid,
              channelid: log_smr.channelid,
              category: log_smr.category,
              section: log_smr.section,
              programid: log_smr.programid,
              clipid: log_smr.clipid,
              ispay: log_smr.ispay,
              vodtype: log_smr.vodtype,
              firstplay: log_smr.firstplay,
              adlink: log_smr.adlink,
              like: 0
            }))
          }
        }
      }

      return payload;
    } catch (error) {
      console.error(error)
    }
  }

  send(position) {
    try {
      // * settting payload counter
      if (!position || isNaN(position)) return false;
      if (SbsPlayerGlobal.infoType === 'onair' || SbsPlayerGlobal.target === 'live') return false;
      if (this.state.retry > 3) return false;

      if (Object.keys(this.payload).length === 0) {
        this.payload = this.getPayload();
      }

      let trackingPoint;
      if (position >= 0.0) trackingPoint = '0';
      if (position >= 0.25) trackingPoint = '1/4';
      if (position >= 0.5) trackingPoint = '2/4';
      if (position >= 0.75) trackingPoint = '3/4';
      if (position >= 0.99) trackingPoint = '4/4';

      if (trackingPoint === '0' && this.state.send.start) return false;
      if (trackingPoint === '1/4' && this.state.send.first && SbsPlayerGlobal.infoType !== 'onair') return false;
      if (trackingPoint === '2/4' && this.state.send.mid && SbsPlayerGlobal.infoType !== 'onair') return false;
      if (trackingPoint === '3/4' && this.state.send.third && SbsPlayerGlobal.infoType !== 'onair') return false;
      if (trackingPoint === '4/4' && this.state.send.end && SbsPlayerGlobal.infoType !== 'onair') return false;

      this.payload.data.trackpoint = trackingPoint;

      fetch(SbsPlayerGlobal.url.gateway, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json;charset=UTF-8'
        }),
        body: JSON.stringify(this.payload)
      }).catch(() => this.state.retry++);

      if (trackingPoint === '0') this.state.send.start = true;
      if (trackingPoint === '1/4') this.state.send.first = true;
      if (trackingPoint === '2/4') this.state.send.mid = true;
      if (trackingPoint === '3/4') this.state.send.third = true;
      if (trackingPoint === '4/4') this.state.send.end = true;
    } catch (error) {
      console.error(error);
    }
  }
}

export default SmrMediaLog;