/**
 * 화면 구현
 */
import SbsPlayerLogger from '../../utils/logger';
import { SbsPlayerGlobal } from '../../common/global';

customElements.define('sbs-player-dialog-live-preview', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['title', 'html']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'title' && (oldValue !== newValue)) {
        this.title = newValue;
      }
      if (name === 'html' && (oldValue !== newValue)) {
        this.html = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }


  // * 디자인 변경
  render() {
    try {
      this.innerHTML = `<div class="${SbsPlayerGlobal.isMobile ? 'playerDialogLive_w' : 'playerDialogLivePc_w'}  current" role="dialog">
          ${SbsPlayerGlobal.isMobile ? `<div class="playerDialogContents">
            <div class="playerDialogTop">
              <p class="playerDialogTop-text">LIVE 미리보기가 종료되었습니다.</p>
              <p class="playerDialogTop-text">SBS APP에서 플레이톡과 함께 시청하세요.</p>
            </div>
            <div class="playerMembershipBox_w">
              <div class="playerMembershipBox">
                <strong class="playerMembershipBox-title">SBS APP</strong>
                <a href="javascript:void(0);" class="playerMembershipBtn">바로가기</a>
              </div>
            </div>
          </div>` : `<div class="playerDialogContents">
            <div class="playerDialogTop">
                <p class="playerDialogTop-text">라이브 미리보기가 종료되었습니다.<br>로그인 후 플레이톡과 함께 끊김 없는 시청이 가능합니다.</p>
            </div>
            <div class="playerLivePcBtns_w">
                <a href="javascript:void(0);" class="btn_black">
                    <strong class="text_bold">자유이용권S</strong>
                    <span class="text_normal">첫 달 1,000원</span>
                </a>
                <a href="javascript:void(0);" class="btn_bgGra">로그인</a>
            </div>
          </div>`}
          
      </div>`;

      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      if (SbsPlayerGlobal.isMobile) this.style.paddingTop = `56.2%`;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      const LOGIN_URL = `https://join.sbs.co.kr/login/login.do?div=pc_login&returnUrl=${encodeURIComponent(location.href)}`;
      const PASS_URL = `https://w3.sbs.co.kr/pass/pass.do?menuType=ticket&returnUrl=${encodeURIComponent(location.href)}`;


      this.querySelectorAll('a').forEach((button) => {
        button.addEventListener('click', (event) => {
          if (event.target.closest('a').classList.contains('playerMembershipBtn')) {
            SbsPlayerGlobal.events.emitEvent(`applink-clicked`, true);
            return;
          }
          if (event.target.closest('a').classList.contains('btn_bgGra')) {
            window.location.href = LOGIN_URL;
            return;
          }
          if (event.target.closest('a').classList.contains('btn_black')) {
            window.location.href = PASS_URL;
            return;
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
});