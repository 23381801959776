/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../common';
import SbsPlayerLogger from '../../../../utils/logger';

import MyVodTime from '../../../../logic/MyVodTime';
import OnairFlag from '../../../../logic/OnairFlag';
import RadioLog from '../../../../logic/RadioLog';
import SmrMediaLog from '../../../../logic/SmrMediaLog';

import { clamp, getExt, importScript } from '../../../../utils';
import { loadCookie } from '../../../../utils/cookie';
import { loadLocalStorage } from '../../../../utils/storage';

customElements.define('sbs-player-video', class extends HTMLElement {
  constructor() {
    super();

    try {
      this.track = null;
      this.controls = false;
      this.src = null;

      this.$video = null;
      this.$player = null;
      this.$track = null;

      this._event = {};
      this._first = true;
    } catch (error) {
      console.log(error);
    }
  }

  set first(value) {
    this._first = value;
  }

  get first() {
    return this._first;
  }

  set video(value) {
    this.$video = value;
  }

  get video() {
    return this.$video;
  }

  set player(value) {
    this.$player = value;
  }

  get player() {
    return this.$player;
  }

  set event(event) {
    // setter 값이 변경될 때 마다 이벤트 전파, 발생시킬 이벤트는 필요한 몇개만..
    this._event = event;
    //this.dispatchEvent(new CustomEvent('video-changed', { detail: event }));
    SbsPlayerGlobal.events.emitEvent('video-change-player', event);
  }

  get event() {
    return this._event;
  }

  static get observedAttributes() { return ['src', 'track']; }
  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'src' && (oldValue !== newValue)) {
        this.src = newValue;
      }
      if (name === 'track' && (oldValue !== newValue)) {
        this.track = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      // * Golf 예외처리 - VOD 중 HLS 스트리밍으로 재생이 안되는 경우 발생 [hls → mp4streaming 으로 변경(2023/04/14)]
      this.innerHTML = `<video id="sbs-player-video-${SbsPlayerGlobal.id.uuid}" ${this.controls ? 'controls' : ''} webkit-playsinline playsinline ${SbsPlayerGlobal.src ? '' : ''} poster="${SbsPlayerGlobal.poster ? SbsPlayerGlobal.poster : ''}" oncontextmenu="return false;" src="${this.src}" width="100%" ${SbsPlayerGlobal.options.crossorigin === '' ? `` : `crossorigin="${SbsPlayerGlobal.options.crossorigin}"`}>
        <p>Your browser doesn't support HTML5 video.</p>
        ${this.track ? `<track label="Korean" kind="subtitles" srclang="ko" src="${this.track}" default>` : ``}
      </video>`;
      console.log('options.muted', SbsPlayerGlobal.options.muted);
      if (SbsPlayerGlobal.options.muted) {
        this.querySelector('video').volume = 0;
      } else {
        this.querySelector('video').volume = clamp(loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume), 0, 1);
      }
      this.styles();
      this.ready();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      const { info, source } = SbsPlayerGlobal.state.data;
      const posterImg = SbsPlayerGlobal.poster || info.defaultimage || (info.thumbs ? info.thumbs['640'] : null) || info.thumbimg || `https://program-image.cloud.sbs.co.kr/${source.channel}.jpg`;

      // for Radio
      if (info && info.type === 'Radio') {
        this.querySelector('video').style.backgroundImage = `url('${posterImg}')`;
        this.querySelector('video').style.backgroundSize = 'cover';
      }

      // 세로영상
      this.querySelector('video').style.height = SbsPlayerGlobal.view.querySelector('.playerWrap').style.height + 'px';

    } catch (error) {
      console.log(error);
    }
  }

  ready() {
    try {
      if (typeof Hls === 'undefined' && getExt(this.src) === 'm3u8') {
        console.log('Hls is undefined', SbsPlayerGlobal.url.lib.hls);
        importScript(SbsPlayerGlobal.url.lib.hls, () => {
          this.initPlayer();
        });
      } else {
        this.initPlayer();
      }
    } catch (error) {
      console.log(error);
    }
  }

  showTrack() {
    try {
      this.$track.track.mode = 'showing';
    } catch (error) {
      console.log(error);
    }
  }
  hideTrack() {
    try {
      this.$track.track.mode = 'hidden';
    } catch (error) {
      console.log(error);
    }
  }

  initPlayer() {
    try {
      console.log('initPlayer');
      const { info, source } = SbsPlayerGlobal.state.data;

      this.$video = this.querySelector('video');
      this.$track = this.querySelector('track');
      this.$player = null;

      if (source && source.subtitle) this.setSubtitle(source.subtitle);
      // FIXME: ! 테스트 하드코딩
      // this.$video.src = 'http://sbslivetest.lgucdn.com/adtest3/live.stream/playlist.m3u8';

      // 미더어 스트림 타입은 'mp4 streaming', 'hls' 만 고려함
      if (getExt(this.$video.src) === 'm3u8') {
        this.$player = new Hls({
          debug: SbsPlayerGlobal.developement,
          enableWorker: true,
          lowLatencyMode: true,
          backBufferLength: 60 * 1.5
        });

        this.$player.subtitleDisplay = true;
        this.$player.subtitleTrack = 0;

        this.$player.loadSource(this.$video.src);
        this.$player.attachMedia(this.$video);
      } else {
        this.$player = this.$video;
      }
      this.eventHandler();
    } catch (error) {
      console.log(error);
    }
  }

  setSubtitle(url) {
    try {
      if (!url) return false;

      this.$track?.remove();

      // ! 트랙을 다시 생성
      let track = document.createElement('track');
      track.kind = 'subtitles';
      track.label = 'Korean';
      track.srclang = 'ko';
      track.src = url.replace('http://', 'https://');
      track.default = true;
      this.$video.appendChild(track);

      this.$track = track;
      this.$track.track.mode = 'hidden';

      this.$track.addEventListener('cuechange', () => {
        console.log('$track cuechange', this.activeCues);
        SbsPlayerGlobal.events.emitEvent('video-track-cuechange', this.activeCues === undefined ? { detail: '' } : { detail: this.activeCues[0].text });
      });
    } catch (error) {
      console.error(error);
    }
  }

  loadSource(media, time) {
    try {
      const { source } = SbsPlayerGlobal.state.data;
      let mediaUrl = media.replace('http://', 'https://');

      if (mediaUrl.indexOf('DVR') === -1) time = this.$video.currentTime;
      this.$video.src = mediaUrl;

      const onAttached = () => {
        this.$player.off(Hls.Events.MEDIA_ATTACHED, onAttached);
        this.$player.on(Hls.Events.MANIFEST_PARSED, onParsed);
        this.$player.loadSource(mediaUrl);
      }

      const onParsed = (event, data) => {
        this.$player.off(Hls.Events.MANIFEST_PARSED, onParsed);

        // * 영상
        this.$player.startLoad(time);
        if (time !== undefined) {
          this.$video.currentTime = time;
        }

        this.$video.play();

        // * 자막
        this.setSubtitle(source.subtitle);
      }

      if (getExt(mediaUrl) === 'm3u8') {
        if (this.$player.constructor.name === 'Hls') {
          this.$player.destroy();
        }
        this.$player = new Hls({
          debug: SbsPlayerGlobal.developement,
          enableWorker: true,
          lowLatencyMode: true,
          backBufferLength: 60 * 1.5,
        });

        this.$player.detachMedia();
        this.$player.on(Hls.Events.MEDIA_ATTACHED, onAttached);
        this.$player.attachMedia(this.$video);
      } else {
        this.$player = this.$video;
        this.$video.currentTime = time;
        this.$player.play();
      }

      this.$video.playbackRate = SbsPlayerGlobal.infoType !== 'onair' && !SbsPlayerGlobal.isMobile ? parseFloat(loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_playback_rate) || 1) : 1;
    } catch (error) {
      console.log(error);
    }
  }

  async senderSbsLog() {
    if (this._first) {
      const { info, source } = SbsPlayerGlobal.state.data;
      if (info === undefined) return false;
      this.first = false;

      const playMode = info.type === 'VOD3MIN' || info.onair_preview_yn === 'Y' ? 'PR' : 'NR';
      const playContinue = info.playduration ? true : false;
      const playDuration = info.duration ? parseInt(info.duration) : 0;

      // ? 23-08-17 1분 마다 전송으로 변경
      const interval = 60_000;

      try {
        // * SBS로그모듈 - 추천로그 contentPlay
        SbsPlayerGlobal.videoLog.contentPlay();

        // * SBS로그모듈 - 추천로그 contentPlaying
        const video = info.duration ? this.querySelector('video') : null;
        let countInterval = 0;
        setInterval(() => {
          if (video !== null && video.paused) {
            console.log('==> paused');
          } else {
            countInterval++;
            SbsPlayerGlobal.videoLog.contentPlaying(playMode, playContinue, playDuration, video, countInterval);
          };
        }, interval);

        // * 스타트 로그
        let startLogUrl = source.mediasourcelist.filter((mediasource) => {
          return mediasource.default === 'Y';
        })[0].start_log_url;
        startLogUrl = startLogUrl?.replace('http://', 'https://');
        if (startLogUrl && window.location.href.indexOf('.sbs.co.kr') > -1) fetch(startLogUrl);
      } catch (error) {
        console.log(error);
      }
    }
  }

  eventHandler() {
    try {
      const video = this.$video;
      const { info, source } = SbsPlayerGlobal.state.data;

      let smrMediaLog = null;
      if (info) {
        if (SbsPlayerGlobal.infoType === 'onair') {
          const flag = new OnairFlag();
          flag.event.initialized = () => {
            console.log('세팅');
            flag.send();
          };
          flag.event.failed = (message) => {
            flag.clear();
            alert(message);
          };
          flag.event.errored = () => {
            flag.clear();
          };
          flag.init(info.flag_url, info.flag_repeat_time);
        }

        // * 이어보기 로그 
        if (info.type === 'VOD' || info.type === 'VODMALL_FULL_AD') {
          let tracker = new MyVodTime();
          tracker.event.initialized = () => {
            tracker.setTracker();
          };
          console.log('> MyVodTime 세팅', info.playduration);
          tracker.init(video, info.playduration);
        }

        //* SMR 미디어로그
        smrMediaLog = new SmrMediaLog();

        // * 라디오 청취자 기초정보 수집 로그
        if (SbsPlayerGlobal.infoType === 'onair' && info.type === 'Radio') {
          let radio = new RadioLog();
          radio.event.initialized = () => {
            radio.sendActionLog();
            radio.sendListenLog(0);
            radio.setTimeout(60);
            radio.setInterval(300);
          };
          radio.event.timeout = () => {
            radio.sendListenLog(1);
            radio.clearTimeout();
          };
          radio.event.interval = () => {
            radio.sendListenLog();
          };
          radio.event.errored = () => {
            radio.clearTimeout();
            radio.clearInterval();
          };
          //const isDev = SbsPlayerGlobal.development;
          const isDev = true;
          radio.init(`https://log.cloud.sbs.co.kr/gorealraapp${isDev ? 'dev' : ''}.playlog`);
        } else {
          // * 라디오가 아니면, SBS로그모듈 - 추천로그
          if (info) SbsPlayerGlobal.videoLog.init(video);
        }
      }


      video.addEventListener('abort', (event) => {
      });
      video.addEventListener('canplay', (event) => {
        this.event = event;
      });
      video.addEventListener('canplaythrough', (event) => {
        this.event = event;
      });
      video.addEventListener('durationchange', (event) => {
        this.event = event;
      });
      video.addEventListener('emptied', (event) => {
      });
      video.addEventListener('ended', (event) => {
        this.event = event;
        if (SbsPlayerGlobal.videoLog) SbsPlayerGlobal.videoLog.contentPlayEnd();
      });
      video.addEventListener('error', (event) => {
        this.event = event;
      });
      video.addEventListener('loadeddata', (event) => {
        this.event = event;

        this._first = true;
      });
      video.addEventListener('play', (event) => {
        this.event = event;
      });
      video.addEventListener('playing', (event) => {
        this.event = event;

        this.senderSbsLog();
      });
      video.addEventListener('pause', (event) => {
        this.event = event;
      });
      video.addEventListener('progress', (event) => {
        this.event = event;
      });

      video.addEventListener('timeupdate', (event) => {
        this.event = event;
        // console.log('activeCues', event.target.textTracks[0].activeCues);
        // * 자막 큐
        if (SbsPlayerGlobal.state.data.source?.subtitle) {
          const propsData = event.target.textTracks && event.target.textTracks.length !== 0 && event.target.textTracks[0].activeCues && event.target.textTracks[0].activeCues.length !== 0 ?
            { detail: event.target.textTracks[0].activeCues[0].text } : { detail: '' }

          SbsPlayerGlobal.events.emitEvent('video-track-cuechange', propsData);
        }
        // * SMR 미디어로그
        if (smrMediaLog) smrMediaLog.send(parseFloat(event.target.currentTime / event.target.duration));
      });
      video.addEventListener('volumechange', (event) => {
        this.event = event;
      });
      video.addEventListener('waiting', (event) => {
        this.event = event;
      });
    } catch (error) {
      console.log(error);
    }

  }

  autoPlay(mediaid, data) {
    try {
      let apiUrl = `https://apis.sbs.co.kr/allvod-api/media_sub/vod/${data.content.pgm_id}?jwt-token=&page=1&sort=ASC&free_yn=N&srs_id=`;
      fetch(apiUrl, {
        method: 'GET',
      })
        .then(response => {
          return response.json();
        })
        .then(data => {

          for (let index = 0; index < data.media.items.length; index++) {

            var inMediaId = data.media.items[index].mda_id.items[0].id;
            if (mediaid == inMediaId) {
              //최종 페이지 이동
              // alert(resultMediaId);
              if (index == data.media.items.length - 1) {
                alert('마지막 화입니다.');
              } else {
                var resultMediaId = data.media.items[index + 1].mda_id.items[0].id;
                window.location.href = "https://allvod.sbs.co.kr/watch/" + resultMediaId;
              }
            }
          }

        });
      // this.event = event;
    } catch (error) {

    }
  }//Finish autoPlay
});