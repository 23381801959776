import { SbsPlayerGlobal } from '../../../../../common';
import SbsPlayerLogger from '../../../../../utils/logger';

/**
 * 플레이어 화면 구현
 */
customElements.define('sbs-player-audio-desc-menu', class extends HTMLElement {
  constructor() {
    super();

    this.activated = false;
    this.opened = false;
    this.panel_id = `sbs-player-subtitle-panel-${SbsPlayerGlobal.id.uuid}`;
    this.menuitem_id = `sbs-player-subtitle-menuitem-${SbsPlayerGlobal.id.uuid}`;
    this.toggle_id = `sbs-player-subtitle-toggle-${SbsPlayerGlobal.id.uuid}`;
  }

  set opened(value) {
    this._opened = value;
    this.render();
  }
  get opened() {
    return this._opened;
  }

  static get observedAttributes() { return ['']; }

  attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (SbsPlayerGlobal.infoType && SbsPlayerGlobal.infoType.indexOf('onair') > -1) {
        return;
      }
      this.innerHTML = `<div id="${this.panel_id}" class="controlSelect-descriptive ${this.opened ? 'open-layer' : ''}">
                <button id="${this.menuitem_id}" type="button" class="btnText-descriptive" aria-haspopup="true" aria-expanded="${this.opened ? 'true' : 'false'}">
                  <span class="text-name">화면해설</span>
                </button>
                <div class="selectLayerWrap-descriptive" style="display: ${this.opened ? 'block' : 'none'}">
                  <button type="button" id="${this.toggle_id}" class="btnLayerSelect-descriptive ${this.activated ? 'current' : ''}" aria-label="화면해설 ${this.activated ? '꺼짐' : '켜짐'}">
                    <strong class="layerText-subtit">화면해설</strong>
                    <span class="layerText-subtext">${this.activated ? 'ON' : 'OFF'}</span>
                  </button>
                </div>
              </div>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector(`#${this.menuitem_id}`).addEventListener('click', (event) => {
        event.preventDefault();
        this.opened = !this.opened;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));
      });

      this.querySelector(`#${this.toggle_id}`)?.addEventListener('click', (event) => {
        this.activated = !this.activated;
        this.opened = false;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));

        this.dispatchEvent(new CustomEvent('button-toggle-audiodesc', { detail: this.activated }));
        this.render();
      });
    } catch (error) {
      console.log(error);
    }

  }
});