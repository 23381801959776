import { SbsPlayerGlobal } from '../../../common';
import { clamp, importScript } from '../../../utils';
import { loadLocalStorage } from '../../../utils/storage';

import SbsPlayerLogger from '../../../utils/logger';

class NetInsightXcAdServer {
  constructor() {
    this.position = '';
    this.uuid = '';
    this.tid = '';
    this.ip = '';
    this.url = '';

    this.$adVideo = null;
    this.$adCotainer = null;
    this.adsLoader = null;
    this.adsManager = null;
    this.adDisplayContainer = null;

    this._data = {};
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      succeeded: () => {
        console.log(`succeeded.`);
      },
      waiting: () => {
        console.log(`waiting.`);
      },
      failed: () => {
        console.log(`failed.`);
      },
      passed: () => {
        console.log(`passed.`);
      },
      errored: () => {
        console.log(`errored.`);
      }
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    return this._data = data;
  }

  get event() {
    return this._event;
  }

  set event(event) {
    return this._event = event;
  }

  initialize(position) {
    try {
      console.log('initialize', position);
      this.position = position;

      let adWrapperElement = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper');
      adWrapperElement.ready();

      if (typeof google === 'undefined' || typeof google.ima === 'undefined') {
        importScript(SbsPlayerGlobal.url.lib.ima3, () => {
          this.setIMA();
        });
      } else {
        this.setIMA();
      }
    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  resize() {
    try {
      const wrapper = SbsPlayerGlobal.view.querySelector('.playerWrap');
      if (wrapper) {
        //const origin = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper .playerVideoCont');
        const width = wrapper.clientWidth;
        const height = wrapper.clientHeight;
        this.$adContainer.style.height = height + 'px';
        this.adsManager.resize(width, height);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setIMA(locale) {
    try {
      const videoHeight = SbsPlayerGlobal.view.querySelector('.playerWrap').clientHeight;

      this.$adVideo = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper video');
      this.$adContainer = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper .playerADVideoCont');

      this.$adContainer.style.height = videoHeight + 'px';
      this.$adContainer.style.zIndex = 10;

      google.ima.settings.setLocale(locale === undefined ? 'ko' : locale);
      google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.INSECURE);

      this.adDisplayContainer = new google.ima.AdDisplayContainer(this.$adContainer, this.$adVideo);
      this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);
      this.adsLoader.addEventListener(
        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        this.onAdsManagerLoaded,
        false);
      this.adsLoader.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        this.onAdError,
        false);
      // this.adsLoader.getSettings().setFeatureFlags({ 'disableClickAdPause': true });

      window.addEventListener('resize', () => {
        try {
          this.resize();
        } catch (error) {
          console.error(error);
        }
      });

      SbsPlayerGlobal.events.addEventListener('button-toggle-playpause', (play) => {
        console.log('button-toggle-playpause', play);
        if (play) {
          this.adsManager.resume();
        } else {
          this.adsManager.pause();
        }
      });

      this.setUrl();
    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  onAdError = (adErrorEvent) => {
    try {
      console.log('onAdError', adErrorEvent.getError());
      this._event.errored(adErrorEvent.getError().toString());
      if (this.adsManager) {
        this.adDisplayContainer.destroy();
      }
    } catch (error) {
      console.log(error);
      this._event.errored();
    }

  };
  onContentPauseRequested = (event) => {
    try {
      event.type = 'pause';
      SbsPlayerGlobal.events.emitEvent('video-change-ad', event);

      this.$adVideo.pause();
    } catch (error) {
      console.error(error);
    }
  };
  onContentResumeRequested = (event) => {
    try {
      event.type = 'play';
      SbsPlayerGlobal.events.emitEvent('video-change-ad', event);
      //this.$adVideo.play();
    } catch (error) {
      console.error(error)
    }
  };

  onAdEvent = (adEvent) => {
    console.log('adEvent', adEvent);
    let ad = adEvent.getAd();
    switch (adEvent.type) {
      case google.ima.AdEvent.Type.LOADED:
        if (!ad.isLinear()) {
          try {
            SbsPlayerGlobal.events.emitEvent('ima-ad-event', 'LOADED');
            this._event.succeeded(ad);
          }
          catch (error) {
            console.log(error);
            this._event.errored();
          }
        }
        break;
      case google.ima.AdEvent.Type.STARTED:
        // * SBS로그모듈 - adPlay (network 광고일 경우 예외처리)
        SbsPlayerGlobal.videoLog.init(this.$adVideo);
        SbsPlayerGlobal.videoLog.adPlay('XC', this.position);
        SbsPlayerGlobal.view.querySelector('.playerWrap').classList.add('playerADType');
        SbsPlayerGlobal.events.emitEvent('ima-ad-event', 'STARTED');

        setTimeout(() => {
          let volume = loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume);
          if (!volume) volume = 0.5;
          this.$adVideo.volume = clamp(volume, 0, 1);
          console.log('xc volume: ' + volume);
          this.adsManager.setVolume(volume);
        }, 500);

        break;
      case google.ima.AdEvent.Type.RESUMED:
        SbsPlayerGlobal.events.emitEvent('ima-ad-event', 'RESUMED');
        break;
      case google.ima.AdEvent.Type.PAUSED:
        SbsPlayerGlobal.events.emitEvent('ima-ad-event', 'PAUSED');
        break;
      case google.ima.AdEvent.Type.CLICK:
        // * 광고더보기(또는 네트워크 광고에 따라 광고화면 클릭시) 발생 -> 멈추지 않고 그냥 재생하도록
        console.log('CLICK', this.$adVideo.paused);
        this.adsManager.resume();
        this.$adVideo.paused ? this.$adVideo.play() : this.$adVideo.play();
        break;
      case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
        SbsPlayerGlobal.events.emitEvent('ima-ad-event', 'COMPLETED');
        SbsPlayerGlobal.events.emitEvent('ima-all-ads-completed');
        break;
    }
  };

  onAdsManagerLoaded = (adsManagerLoadedEvent) => {
    try {
      console.log('adsManagerLoadedEvent', adsManagerLoadedEvent);

      let adsRenderingSettings = new google.ima.AdsRenderingSettings();
      // ? restoreCustomPlaybackStateOnAdBreakComplete: 광고 시간이 완료된 후 SDK가 맞춤 재생 상태를 복원해야 하는지 여부를 지정합니다. 이 설정은 게시자가 맞춤 광고 재생에 사용하기 위해 콘텐츠 플레이어를 전달할 때 주로 사용됩니다.
      adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
      this.adsManager = adsManagerLoadedEvent.getAdsManager(this.$adVideo, adsRenderingSettings);
      this.adsManager.setVolume(0);

      this.adsManager.addEventListener(
        google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, () => {
          this.onContentPauseRequested(google.ima.AdEvent)
        }, false, this);
      this.adsManager.addEventListener(
        google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, () => {
          this.onContentResumeRequested(google.ima.AdEvent)
        }, false, this);
      this.adsManager.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        this.onAdError, false, this);

      let events = [google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      google.ima.AdEvent.Type.CLICK,
      google.ima.AdEvent.Type.COMPLETE,
      google.ima.AdEvent.Type.FIRST_QUARTILE,
      google.ima.AdEvent.Type.LOADED,
      google.ima.AdEvent.Type.MIDPOINT,
      google.ima.AdEvent.Type.PAUSED,
      google.ima.AdEvent.Type.RESUMED,
      google.ima.AdEvent.Type.STARTED,
      google.ima.AdEvent.Type.THIRD_QUARTILE,
      google.ima.AdEvent.Type.SKIPPED
      ];
      events.forEach((events, eventsIndex) => {
        this.adsManager.addEventListener(events, this.onAdEvent, false, this);
      });


      // Initialize the container. Must be done via a user action on mobile devices.
      this.$adVideo.load();
      this.adDisplayContainer.initialize();

      try {
        console.log('adsManager.init', this.$adVideo, this.$adVideo.clientWidth, this.$adVideo.clientHeight);
        this.adsManager.init(this.$adVideo.clientWidth, this.$adVideo.clientHeight, google.ima.ViewMode.NORMAL);

        this.adsManager.start();

        this.resize();
      } catch (adError) {
        // Play the video without ads, if an error occurs
        console.log("AdsManager could not be started");
        console.error(adError);
      }
    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  };

  setUrl() {
    try {
      let url = this._data[this.position].link;
      if (url) {
        this.url = new URL(url);
        this.url.protocol = "https:";

        let uuid = this.uuid;
        let tid = this.tid;
        let ip = this.ip;
        let cmparam = this._data.cmparam;

        let params = new URLSearchParams(cmparam);
        params.set('d.ifa', uuid);
        params.set('d.lmt', '0');
        params.set('d.model', '');
        params.set('d.w', '');
        params.set('d.h', '');
        params.set('d.ip', ip);
        params.set('u.customdata', '');
        params.set('i.v.api', '');
        params.set('i.v.startdelay', '');
        params.set('i.v.w', this.$adVideo.clientWidth);
        params.set('i.v.h', this.$adVideo.clientHeight);
        params.set('slots', '');
        params.set('rurl', encodeURIComponent(document.location.href));
        this.url = this.url + '?' + params.toString();
        this._event.initialized();
      } else {
        this._event.passed();
      }

    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  requestAd() {
    try {
      console.log(this.position, this.url);
      let adsRequest = new google.ima.AdsRequest();
      adsRequest.adTagUrl = this.url;
      adsRequest.linearAdSlotWidth = this.$adVideo.clientWidth;
      adsRequest.linearAdSlotHeight = this.$adVideo.clientHeight;
      adsRequest.nonLinearAdSlotWidth = this.$adVideo.clientWidth;
      adsRequest.nonLinearAdSlotHeight = this.$adVideo.clientHeight;
      this.adsLoader.requestAds(adsRequest);
    } catch (error) {
      console.log(error);
      this._event.errored(error);
    }
  }
}

export default NetInsightXcAdServer;