/**
 * 콘텍스트메뉴 대체
 */
import { SbsPlayerGlobal } from '../../../../common';
import SbsPlayerLogger from '../../../../utils/logger';
import './backward';
import './forward';
import './playpause';
import './replay';
import './timeshiftText';

customElements.define('sbs-player-center-buttons', class extends HTMLElement {
  constructor() {
    super();
    this.visible = true;
    this.ended = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerVideoBtns" style="display: ${this.visible ? 'flex' : 'none'}">
          <sbs-player-center-backward></sbs-player-center-backward>
          <sbs-player-center-playpause paused="true"></sbs-player-center-playpause>
          <sbs-player-center-replay></sbs-player-center-replay></sbs-player-center-replay>
          <sbs-player-center-forward></sbs-player-center-forward>        
        </div>
      </div>
      <sbs-player-center-timeshift-text></sbs-player-center-timeshift-text>`;
      this.event();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
    } catch (error) {
      console.error(error);
    }
  }

  event() {
    try {
      const loading = SbsPlayerGlobal.view.querySelector('sbs-player-loading');
      const centerButtonsElement = this.querySelector('.playerVideoBtns');
      const playpause = this.querySelector('sbs-player-center-playpause');
      const replay = this.querySelector('sbs-player-center-replay');
      const backward = this.querySelector('sbs-player-center-backward');
      const forward = this.querySelector('sbs-player-center-forward');

      if (SbsPlayerGlobal.infoType === 'onair') {
        backward.style.visibility = 'hidden';
        forward.style.visibility = 'hidden';
        replay.style.visibility = 'hidden';
      }

      SbsPlayerGlobal.events.addEventListener('video-change-player', (event) => {
        try {
          if ('play' === event.type) {
            loading.visible = false;
            playpause.visible = true;
            replay.visible = false;
            centerButtonsElement.classList.remove('playerVideoBtns-replay');
            playpause.setAttribute('paused', 'false');
            setTimeout(() => {
              SbsPlayerGlobal.events.emitEvent('controller-hide-event', true);
            }, SbsPlayerGlobal.options.hide_contorls_seconds);
          }

          if ('pause' === event.type) {
            loading.visible = false;
            playpause.visible = true;
            replay.visible = false;
            centerButtonsElement.classList.remove('playerVideoBtns-replay');
            playpause.setAttribute('paused', 'true');
            SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
          }
          if ('ended' === event.type) {
            loading.visible = false;
            playpause.visible = false;
            replay.visible = true;
            centerButtonsElement.classList.add('playerVideoBtns-replay');
          }

          if ('canplay' === event.type) {
            loading.visible = false;
            playpause.visible = true;
            replay.visible = false;
            centerButtonsElement.classList.remove('playerVideoBtns-replay');
          }

          if ('waiting' === event.type) {
            loading.visible = true;
            playpause.visible = false;
            replay.visible = false;
          }
        } catch (error) {
          console.log(error);
        }

      });

      const elements = [
        { element: playpause, event: 'button-toggle-playpause' },
        { element: replay, event: 'button-click-replay' },
        { element: backward, event: 'button-click-backward' },
        { element: forward, event: 'button-click-forward' },
      ];

      for (const { element, event } of elements) {
        if (!element) continue;
        element.addEventListener(event, (e) => {
          this.dispatchEvent(new CustomEvent(event, { detail: e.detail }));
        });
      }

      SbsPlayerGlobal.events.addEventListener('controller-hide-event', (hidden) => {
        const hideControls = this.querySelector('.playerVideoBtns');
        hidden ? hideControls.classList.add('hide_controls') : hideControls.classList.remove('hide_controls');
      });
      SbsPlayerGlobal.events.addEventListener('center-hide-event', (hidden) => {
        const hideControls = this.querySelector('.playerVideoBtns');
        hidden ? hideControls.style.display = 'none' : hideControls.style.display = 'flex';
      });
    } catch (error) {
      console.log(error);
    }
  }
});