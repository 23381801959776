// * [front-pc] /app/src/js/model/end/common/player.js
// * VOD 재생시 화질변경시
// changeRscuse(device, rscuse) {
//   if (this.view.media.info.type != 'CLIP') {
//     if (rscuse === '05' || rscuse === '07') {
//       if (this.state.mediaSource.mediaList.C.billYn !== 'Y') {
//         billingPopup('vods', `${this.state.mediaSource.mediaList.C.rscId}`);
//       }
//     } else if (rscuse === '02') {
//       if (this.state.vodbutton !== 'free' && this.state.mediaSource.mediaList.B.billYn !== 'Y') {
//         billingPopup('vods', `${this.state.mediaSource.mediaList.B.rscId}`);
//       }
//     }
//   }
//   STORAGE_UTIL.save(SBS_PROGRAM_GLOBAL.STORAGE.CURRENT_VOD_RSCUSE, rscuse + '');
// }

// * 각 페이지에서 처리하는 식으로 원복
window.billingPopup = (serviceCode, cpitemId) => {
  serviceCode ??= 'vods';
  //const LOGIN_URL = `https://join.${process.env.NODE_ENV === 'development' ? 'developers' : ''}sbs.co.kr/login/login.do?div=pc_login&returnUrl=` + encodeURIComponent(location.href);
  const LOGIN_URL = `https://join.sbs.co.kr/login/login.do?div=pc_login&returnUrl=` + encodeURIComponent(location.href);

  const LOCAL_STORAGE_KEY = 'sbs-program-player-autoPopup';

  function loadCookie(key) {
    try {
      let rawName = key + '=';
      let rawCookies = document.cookie.split(';');

      for (let index = 0; index < rawCookies.length; index++) {
        let rawCookie = rawCookies[index];

        while (rawCookie.charAt(0) === ' ') {
          rawCookie = rawCookie.substring(1);
        }

        if (rawCookie.indexOf(rawName) === 0) {
          return decodeURIComponent(rawCookie.substring(rawName.length, rawCookie.length));
        }
      }

      return '';
    }
    catch (error) {
      console.log(error);
    }
  }

  if (serviceCode === 'onair') {
    if (confirm('라이브 이용권 구매 후 시청 가능합니다.')) {
      window.location.href = `https://w3.sbs.co.kr/pass/pass.do?menuType=ticket&returnUrl=${encodeURIComponent(location.href)}`;
      return false;
    }
  } else {
    if (!loadCookie('LOGIN_JWT')) {
      if (confirm('로그인 및 구매 후 시청 가능합니다. 로그인 하시겠습니까?')) {
        // * 로그인에서 return_url 로 돌아왔을 때 재귀호출을 위해 작성
        localStorage.setItem(LOCAL_STORAGE_KEY, 'billingPopup(\'' + serviceCode + '\',\'' + cpitemId + '\')');

        window.location.href = LOGIN_URL;
        return false;
      } else { return; }
    }
    else {
      // program template mapping
      // B: 02, 일반화질, 480p
      // C: 05, 고화질, 720p
      // D: 07, 초고화질, 1080p
      // E: 09, UHD, 2160p
      if (confirm('구매 후 이용 가능합니다. 구매하시겠습니까?')) {
        //구매하기 팝업
        let cpid = '107';
        serviceCode === 'vods' ? cpid = '107' : cpid = '110';

        // * popupCloser
        let returnUrl = location.origin + '/closer.html';
        serviceCode === 'vods' ? '' : returnUrl += '?callfunction=opener.vodDownBillingEnd';

        let billUrl = 'https://billing.sbs.co.kr/w3/pay/charge_item_form.jsp?contflag=1&servicecode=' + serviceCode
          + '&cpid=' + cpid + '&cpitemid=' + cpitemId + '&host=' + location.host + '&returnurl=' + encodeURIComponent(returnUrl);
        let remote = window.open(billUrl, 'VOD구매', "width=570, height=750, resizable=yes, scrollbars=yes");

        if (remote !== null && remote.opener) {
          remote.opener = self;
          //window.reload();
        }
      } else { return; }
    }
  }
}