export * from './compatibility';
import { SbsPlayerGlobal } from '../common';

import SbsPlayerLogger from './logger';
export const instances = [];

export class SbsHtml5PlayerError extends Error {
  constructor(message, context) {
    super(message);
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, context || this.constructor);
    }
    this.name = 'SbsHtml5PlayerError';
  }
}

export function errorHandle (condition, msg) {
  const isFun = typeof condition === 'function';
  if (isFun ? !isFun() : !condition) {
    throw new SbsHtml5PlayerError(msg);
  }
}
/** 
* lower 및 upper 포괄적인 범위에 고정되어 있는 num 반환합니다.
*
*/
export function clamp(num, lower, upper) {
  let result = Math.min(Math.max(num, lower), upper);
  result = isNaN(result) ? upper : result;
  return result;
}

export function append (parent, child) {
  if (child instanceof Element) {
    parent.appendChild(child);
  } else {
    parent?.insertAdjacentHTML('beforeend', child);
  }
  return parent.lastElementChild;
}

export function addClass (target, className) {
  return target.classList.add(className);
}

export function proxy (target, name, callback, option = {}) {
  if (Array.isArray(name)) {
    name.forEach(item => this.proxy(target, item, callback, option));
    return;
  }

  target.addEventListener(name, callback, option);
  // this.destroyEvents.push(() => {
  //   target.removeEventListener(name, callback, option);
  // });
}

export function hover (target, mouseenter, mouseleave) {
  proxy(target, 'mouseenter', mouseenter);
  proxy(target, 'mouseleave', mouseleave);
}

export function removeEvent (target, name, callback, option = {}) {
  target.removeEventListener(name, callback, option);
}

export function destroy () {
  // this.destroyEvents.forEach(event => event());
}

export function insertByIndex (parent, child, index) {
  const childs = Array.from(parent.children);
  child.dataset.index = index;
  const nextChild = childs.find(item => Number(item.dataset.index) >= Number(index));
  if (nextChild) {
    nextChild.insertAdjacentElement('beforebegin', child);
  } else {
    append(parent, child);
  }
  return child;
}

export function setStyle (element, key, value) {
  element.style[key] = value;
  return element;
}

export function setStyles (element, styles) {
  Object.keys(styles).forEach(key => {
    setStyle(element, key, styles[key]);
  });
  return element;
}

export function secondToTime (second) {
  const add0 = num => num < 10 ? `0${num}` : String(num);
  const hour = Math.floor(second / 3600);
  const min = Math.floor((second - hour * 3600) / 60);
  const sec = Math.floor(second - hour * 3600 - min * 60);
  const result = (hour > 0 ? [hour, min, sec] : [min, sec]).map(add0).join(':');
  return result;
}

export function deepMerge (...sources) {
  const isObject = value => value !== null && typeof value === 'object';
  let returnValue = {};
  for (const source of sources) {
    if (Array.isArray(source)) {
      if (!Array.isArray(returnValue)) {
        returnValue = [];
      }
      returnValue = [...returnValue, ...source];
    } else if (isObject(source)) {
      if (source instanceof Element) {
        return source;
      }
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          let value = source[key];
          if (isObject(value) && key in returnValue) {
            value = deepMerge(returnValue[key], value);
          }
          returnValue = { ...returnValue, [key]: value };
        }
      }
    }
  }
  return returnValue;
}

export function getStorage (key) {
  const storage = JSON.parse(localStorage.getItem('SbsPlayer_settings')) || {};
  return key ? storage[key] : storage;
}

export function setStorage (key, value) {
  const storage = Object.assign({}, getStorage(), {
    [key]: value
  });
  localStorage.setItem('SbsPlayer_settings', JSON.stringify(storage));
}

export function tooltip (target, msg) {
  target.setAttribute('title', msg);
}

export function sleep (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function sublings (target) {
  return Array.from(target.parentElement.children).filter(item => item !== target);
}

export function inverseClass (target, className) {
  sublings(target).forEach((item) => removeClass(item, className));
  addClass(target, className);
}

export function query (selector, parent = document) {
  return parent.querySelector(selector);
}

export function queryAll (selector, parent = document) {
  return Array.from(parent.querySelectorAll(selector));
}

export function debounce (func, wait, context) {
  let timeout;
  function fn () {
    const args = arguments;
    const later = function later () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  fn.clearTimeout = function ct () {
    clearTimeout(timeout);
  };

  return fn;
}

export function toFormattedDate (date, format) {
  let weekNames = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];

  return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p|y\/t)/gi, ($1) => {
    switch ($1) {
      case 'yyyy': return date.getFullYear();
      case 'yy': return (date.getFullYear() % 1000);
      case 'MM': return (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
      case 'dd': return date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
      case 'E': return weekNames[date.getDay()];
      case 'HH': return date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours();
      // case 'hh': return ((h = date.getHours() % 12) ? h : 12);
      case 'mm': return date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes();
      case 'ss': return date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds();
      case 'a/p': return date.getHours() < 12 ? '오전' : '오후';
      case 'y/t': return date.getDate() === new Date().getDate() ? '오늘' : '어제';
      default: return $1;
    }
  });
}

/**
 * HH:MM:SS 포맷을 초로 변경한다.
 * @param hhmmss{string}
 * @returns {integer}
 */
export function convertTimeStampToSeconds (hhmmss) {
  let temp = hhmmss.split(':'),
    seconds = 0, m = 1;

  while (temp.length > 0) {
    seconds += m * parseInt(temp.pop(), 10);
    m *= 60;
  }

  return seconds;
}

/**
 * 초를 HH:MM:SS 포맷으로 변경한다.
 * @param hhmmss{string}
 * @returns {string}
 */
export function secondsToHms (seconds) {
  seconds = Number(seconds);
  let h = Math.floor(seconds / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  let s = Math.floor(seconds % 3600 % 60);

  var hDisplay = (h < 10 ? "0" : "") + h;
  var mDisplay = (m < 10 ? "0" : "") + m;
  var sDisplay = (s < 10 ? "0" : "") + s;
  return h !== 0 ? hDisplay + ':' + mDisplay + ':' + sDisplay : mDisplay + ':' + sDisplay;
}

/**
 * 초를 --시, --분, --초 로 변경한다. wai-aria
 * @param hhmmss{string}
 * @returns {string}
 */
export function secondsToText (seconds) {
  seconds = Number(seconds);
  let h = Math.floor(seconds / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  let s = Math.floor(seconds % 3600 % 60);
  return h !== 0 ? h + '시 ' + m + '분 ' + s + '초' : m + '분 ' + s + '초';
}

/**
 * object type을 getParams 로 변경한다.
 * @param data{object}
 * @returns {string}
 */
export function getObjectToUriParameter (data) {
  try {
    let uriParameter = '';

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        uriParameter += key + '=' + data[key] + '&';
      }
    }

    if (uriParameter.length > 0) {
      uriParameter = uriParameter.substring(0, uriParameter.length - 1);
    }

    return uriParameter;
  }
  catch (error) {
    console.log(error);
    return '';
  }
}

/**
 * 미디어스트림 타입을 구별합니다.
 * @param media_stream_url {string}
 * @returns {string}
 */
export function getExt (url) {
  const match = url.match(/(.+?)\?/);
  if (match) url = match[1];

  const index = url.lastIndexOf('.') + 1;
  return url.substring(index);
}

/**
 * 쿼리파라미터 문자열을 분리합니다.
 * @param serch_query_params_string {string}
 * @returns {object}
 */
export function separateQueryParams (queryString) {
  let params = {};
  let queries = queryString.split("&");
  for (let i = 0; i < queries.length; i++) {
    let pair = queries[i].split("=");
    params[pair[0]] = pair[1];
  }
  return params;
}

/**
 * 헤더 스크립트추가.
 * @param url{string}, callback 
 * @returns {object}
 */
export function importScript (url, callback) {
  let script = document.createElement('script');
  script.onload = callback;
  script.src = url;
  return document.head.appendChild(script);
}

/**
 * UUID 생성
 * @param 
 * @returns {string}
 */
export function generateUUID () {
  let time = new Date().getTime();
  return 'sbs-player-xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, (char) => {
    let random = (time + Math.random() * 16) % 16 | 0;
    time = Math.floor(time / 16);
    return (char === 'x' ? random : (random & 0x3 | 0x8)).toString(16);
  });
};

export function unmute () {
  try {
    SbsPlayerGlobal.options.muted = false;
  } catch (error) {
    console.error(error);
  }
}