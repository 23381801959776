import { clamp } from '../utils';
import SbsPlayerLogger from '../utils/logger';
import { SbsPlayerGlobal } from './global';


/**
 * 모바일 더블터치시 seek
 *
 * @export
 * @class Touch
 */
export class Touch {
  constructor(props) {
    this.$container = SbsPlayerGlobal.view.querySelector('.playerWrap');
    this.init();
  }

  isTouchesEnabledCenterControl(event) {
    try {
      if (!event || !event.touches) return false;
      const targetElement = SbsPlayerGlobal.view.querySelector('sbs-player-center-buttons .playerVideoBtns'); // 대상 엘리먼트의 ID를 지정합니다.

      var touchX = event.touches[0].clientX;
      var touchY = event.touches[0].clientY;

      var elementRect = targetElement.getBoundingClientRect();
      var elementX = elementRect.left;
      var elementY = elementRect.top;
      var elementWidth = elementRect.width;
      var elementHeight = elementRect.height;

      // 터치한 위치가 대상 엘리먼트 내에 있는지 확인합니다.
      if (touchX >= elementX && touchX <= elementX + elementWidth &&
        touchY >= elementY && touchY <= elementY + elementHeight) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  isTouchesEnabledAdCenterControl(event) {
    try {
      if (!event || !event.touches) return false;
      const targetElement = SbsPlayerGlobal.view.querySelector('sbs-player-ad-center-buttons .playerVideoBtns'); // 대상 엘리먼트의 ID를 지정합니다.

      var touchX = event.touches[0].clientX;
      var touchY = event.touches[0].clientY;

      var elementRect = targetElement.getBoundingClientRect();
      var elementX = elementRect.left;
      var elementY = elementRect.top;
      var elementWidth = elementRect.width;
      var elementHeight = elementRect.height;

      // 터치한 위치가 대상 엘리먼트 내에 있는지 확인합니다.
      if (touchX >= elementX && touchX <= elementX + elementWidth &&
        touchY >= elementY && touchY <= elementY + elementHeight) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  isTouchesEnabledBottomControl(event) {
    try {
      if (!event || !event.touches) return false;
      const targetElement = SbsPlayerGlobal.view.querySelector('sbs-player-controls .playerControls_w'); // 대상 엘리먼트의 ID를 지정합니다.

      var touchX = event.touches[0].clientX;
      var touchY = event.touches[0].clientY;

      var elementRect = targetElement.getBoundingClientRect();
      var elementX = elementRect.left;
      var elementY = elementRect.top;
      var elementWidth = elementRect.width;
      var elementHeight = elementRect.height;

      // 터치한 위치가 대상 엘리먼트 내에 있는지 확인합니다.
      if (touchX >= elementX && touchX <= elementX + elementWidth &&
        touchY >= elementY && touchY <= elementY + elementHeight) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  isTouchesEnabledAdBottomControl(event) {
    try {
      if (!event || !event.touches) return false;
      const targetElement = SbsPlayerGlobal.view.querySelector('sbs-player-ad-controls .playerControls_w'); // 대상 엘리먼트의 ID를 지정합니다.

      var touchX = event.touches[0].clientX;
      var touchY = event.touches[0].clientY;

      var elementRect = targetElement.getBoundingClientRect();
      var elementX = elementRect.left;
      var elementY = elementRect.top;
      var elementWidth = elementRect.width;
      var elementHeight = elementRect.height;

      // 터치한 위치가 대상 엘리먼트 내에 있는지 확인합니다.
      if (touchX >= elementX && touchX <= elementX + elementWidth &&
        touchY >= elementY && touchY <= elementY + elementHeight) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  isTouchesEnabledTopButton(event) {
    try {
      if (!event || !event.touches) return false;
      const targetElement = SbsPlayerGlobal.view.querySelector('sbs-player-toparea-app'); // 대상 엘리먼트의 ID를 지정합니다.

      if (!targetElement) return true;

      var touchX = event.touches[0].clientX;
      var touchY = event.touches[0].clientY;

      var elementRect = targetElement.getBoundingClientRect();
      var elementX = elementRect.left;
      var elementY = elementRect.top;
      var elementWidth = elementRect.width;
      var elementHeight = elementRect.height;

      // 터치한 위치가 대상 엘리먼트 내에 있는지 확인합니다.
      if (touchX >= elementX && touchX <= elementX + elementWidth &&
        touchY >= elementY && touchY <= elementY + elementHeight) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  init() {
    if (!SbsPlayerGlobal.isMobile) return false;

    const toggleHideControls = (event) => {
      try {
        if (this.isTouchesEnabledBottomControl(event)
          && this.isTouchesEnabledAdBottomControl(event)
          && this.isTouchesEnabledCenterControl(event)
          && this.isTouchesEnabledAdCenterControl(event)
          && this.isTouchesEnabledTopButton(event)) {
          if (this.timoutEventHideController) {
            // * 콘트롤러 활성화 상태 -> 바로 숨김
            this.hideControls();
          } else {
            // * 콘트롤러 숨김 상태 -> 바로 보임
            this.showControls();
          }
        }
      } catch (error) {
        console.log(error);
      };
    }

    toggleHideControls();
    this.$container.addEventListener('touchmove', () => {
      SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
      clearTimeout(this.timoutEventHideController);
    });
    this.$container.addEventListener('touchstart', toggleHideControls, true);
    //this.$container.addEventListener('touchend', this.hideControls());
  }

  showControls() {
    try {
      SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
      clearTimeout(this.timoutEventHideController);
      this.timoutEventHideController = setTimeout(() => {
        SbsPlayerGlobal.events.emitEvent('controller-hide-event', true);
        this.timoutEventHideController = null;
      }, SbsPlayerGlobal.options.hide_contorls_seconds);
    } catch (error) {
      console.error(error);
    }
  }
  hideControls() {
    try {
      SbsPlayerGlobal.events.emitEvent('controller-hide-event', true);
      clearTimeout(this.timoutEventHideController);
      this.timoutEventHideController = null;
    } catch (error) {
      console.error(error);
    }
  }
}
