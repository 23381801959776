/**
 * 광고 화면 구현
 */
import { SbsPlayerGlobal } from '../../../common';
import { clamp } from '../../../utils';
import { loadLocalStorage, saveLocalStorage } from '../../../utils/storage';

import SbsPlayerLogger from '../../../utils/logger';

import './AdInfoArea';
import './AdVideo';
import './Center';
import './Controls';

customElements.define('sbs-player-ad-wrapper', class extends HTMLElement {
  constructor() {
    super();
    this.position = '';
    this.platform = {};
    this.campaign = {};
    this.sender = null;
    this.adSequenceText = '';

  }

  set position(value) {
    this._position = value;
  }

  get position() {
    return this._position;
  }

  async connectedCallback() {
    try {
      // ad-recieved 시 렌더링
      // this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  ready() {
    try {
      // for network ad
      console.log('sbs-player-ad-wrapper > ready');
      this.dispatchEvent(new CustomEvent('ad-container-ready'));
      this.style.display = 'block';
      // 로딩 필요..
      this.innerHTML = `<div class="playerVideo_w">
        <div class="playerVideoCont">
          <video style="width: 100%; height: 100%;"></video>
        </div>
        <div class="playerADVideoCont"></div>
        <sbs-player-ad-center-buttons></sbs-player-ad-center-buttons>
      </div>
      <sbs-player-ad-sequence style="position: absolute; bottom: 0;" text=${this.adSequenceText}></sbs-player-ad-sequence>`;
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      const isFullScreenMode = document.fullscreenElement !== null;
      if (isFullScreenMode) {
        this.querySelector('video').style.height = '100vh';
      } else {
        this.querySelector('video').style.height = '100%';
      }
    } catch (error) {
      console.error(error);
    }
  }

  count(text) {
    try {
      this.adSequenceText = text;
    } catch (error) {
      console.log(error);
    }
  }

  render(ads) {
    try {
      SbsPlayerGlobal.view.querySelector('.playerWrap').classList.add('playerADType');
      this.style.opacity = 1;
      this.style.display = 'block';
      this.style.width = '100%';
      this.style.height = '100%';
      this.platform = ads.platform;
      this.campaign = ads.campaign;
      this.adSequenceText = ads.adSequenceText;

      let isNetworkAds = this.campaign.ads === undefined;
      let mediaUrl = isNetworkAds ? this.campaign.getMediaUrl() || '' : this.campaign.ads[0].content_url;
      mediaUrl = mediaUrl.replace('http://', 'https://');
      let isLiveMidRoll = SbsPlayerGlobal.live_midroll.duration;
      this.innerHTML = `<div class="playerVideo_w">
        <div class="playerVideoCont">
          <sbs-player-ad-video src=${mediaUrl}></sbs-player-ad-video>
        </div>
        ${ isLiveMidRoll ? `` : `<sbs-player-ad-center-buttons></sbs-player-ad-center-buttons>`}
      </div>
      ${this.campaign ? `<sbs-player-ad-info-area 
      duration="${SbsPlayerGlobal.live_midroll.duration ? SbsPlayerGlobal.live_midroll.duration : ''}" 
      text="${SbsPlayerGlobal.live_midroll.title ? SbsPlayerGlobal.live_midroll.title : ''}"></sbs-player-ad-info-area>` : ``}
      <sbs-player-ad-controls sequence_text=${this.adSequenceText}></sbs-player-ad-controls>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }


  event() {
    try { 
      const infoAreaElement = this.querySelector('sbs-player-ad-info-area');
      const videoElement = this.querySelector('sbs-player-ad-video');
      const centerButtons = this.querySelector('sbs-player-ad-center-buttons');
      const controller = this.querySelector('sbs-player-ad-controls');

      if (this.campaign) {
        infoAreaElement.position = this.position;
        infoAreaElement.campaign = this.campaign;
        videoElement.campaign = this.campaign;
      }
      infoAreaElement?.addEventListener('ad-more-click', () => {
        //videoElement.video.pause();
        //this.dispatchEvent(new CustomEvent('ad-more-click'));
        let clickThroughUrl = this.campaign.ads[0].tracking_url.click || this.campaign.ads[0].tracking_url.clickThrough || this.campaign.ads[0].tracking_url.clickTracking
          || this.campaign.ads.tracking_url.click || this.campaign.ads.tracking_url.clickThrough || this.campaign.ads.tracking_url.clickTracking;
        window.open(clickThroughUrl);
        fetch(this.campaign.ads[0].tracking_url.clickTracking || this.campaign.ads.tracking_url.clickTracking);
      });
      infoAreaElement?.addEventListener('ad-skip-click', () => {
        videoElement.video.pause();
        this.dispatchEvent(new CustomEvent('ad-skip-click'));
        let skipTrackingUrl = this.campaign.ads[0].tracking_url.skip || this.campaign.ads.tracking_url.skip;
        fetch(skipTrackingUrl);
      });
      centerButtons?.addEventListener('button-toggle-playpause', (event) => {
        let paused = event.detail;
        paused ? videoElement.video.play() : videoElement.video.pause();
      });
      controller.addEventListener('button-toggle-playpause', (event) => {
        let paused = event.detail;
        paused ? videoElement.video.play() : videoElement.video.pause();
      });
      controller.addEventListener('button-click-replay', (event) => {
        videoElement.video.currentTime = 0;
        videoElement.video.play();
      });
      controller.addEventListener('button-toggle-volume', (event) => {
        let muted = event.detail;
        videoElement.video.volume = muted ? 0.5 : 0;
      });
      controller.addEventListener('button-toggle-fullscreen', (event) => {
        this.dispatchEvent(new CustomEvent('button-toggle-fullscreen', { detail: event.detail }));
      });
      controller.addEventListener('input-change-volume', (event) => {
        let value = event.detail;
        let changeVolume = value && clamp(value, 0, 1);
        videoElement.video.volume = changeVolume;
        saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume, changeVolume);
      });
      // * unmute 컴포넌트에서 발생하는 이벤트
      SbsPlayerGlobal.events.addEventListener('input-change-volume', (volume) => {
        let changeVolume = clamp(volume, 0, 1);
        videoElement.video.volume = changeVolume;
        saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume, changeVolume);
      });
      // * SBS로그모듈 - adPlay
      SbsPlayerGlobal.videoLog.adPlay(this.platform, this.position);

      SbsPlayerGlobal.events.addEventListener('autoplay-failed', () => {
        try {
          centerButtons.style.display = 'block';
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }


  }

  ended() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
});