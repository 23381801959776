export const SbsPlayerGlobal = {
  config: {
    name: 'OMP',
    version: '1.2.0',
    build: '',
  },
  isMobile: false,
  src: null,
  poster: null,
  id: {},
  state: {
    type: '',
    data: {},
    ad: {},
    rscuse: '',
    isSubtitle: false,
    isAudioDesc: false,
    isUseAppLink: true,

    toast: {
      isShowSubtitleNotice: false,
    }
  },
  url: {
    gateway: 'https://gateway.cloud.sbs.co.kr/log',
    share: 'https://static.cloud.sbs.co.kr/sbs-player/index.html',
    info: null,
    lib: {
      // https://cdn.jsdelivr.net/npm/hls.js@latest
      hls: 'https://static.cloud.sbs.co.kr/libs/hls/1.4.10/hls.min.js',
      ima3: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
      vast_parser: 'https://static.cloud.sbs.co.kr/libs/vast-parser/1.0.1/vast-parser.min.js',
      sbs_video_log: 'https://static.cloud.sbs.co.kr/common/js/1.1.0/sbs_log_module.js',
      swiper: 'https://static.cloud.sbs.co.kr/libs/swiper/8.4.7/swiper.min.js',
    },
    api: {
      jwt: 'https://apis.sbs.co.kr/program-api/1.0/convert_json'
    },
    cdn: {
      styles: 'https://static.cloud.sbs.co.kr/styles/sbs-player.css'
    },
    next_episode_link: ''
  },
  storage: {
    sbsplayer_use_continuos_play: 'sbs-program-playlist-useContinuousPlay',
    sbsplayer_rscuse: 'sbs-player-rscuse',
    sbsplayer_volume: 'sbs-player-volume',
    sbsplayer_playback_rate: 'sbs-player-playback-rate',
    sbsplayer_subtitle: 'sbs-player-subtitle',
    sbsplayer_audiodesc: 'sbs-player-audiodesc'
  },
  view: null,
  type: 'desktop',
  target: 'SBS',
  options: {
    noad: false,
    allow_adblock: false
  },
  control: {
    shows: [],
    hiddens: []
  },
  events: {},
  hotkey: {},
  touch: {},
  live_midroll: {
    is_on: false,
    rd_queue: [],
    ct_queue: []
  },
  vodtype: '', // allvod 에서 사용
  vodcurationid: '', // allvod 에서 사용
  vodprogramhome: '', // allvod 에서 사용
  vodmediaid: '', // allvod 에서 사용
  vodcurationtype: '' // allvod 에서 사용
}