/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../common';
import { clamp, getExt, importScript } from '../../../../utils';
import { loadLocalStorage } from '../../../../utils/storage';

import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-ad-video', class extends HTMLElement {
  constructor() {
    super();

    try {
      this._event = {};
      this.$video = null;
      this.$player = null;
      this.src = null;
      this.controls = false;
      this.campaign = null;
    } catch (error) {
      console.log(error);
    }
  }

  set video(value) {
    this.$video = value;
  }

  get video() {
    return this.$video;
  }

  set campaign(value) {
    this._campaign = value;
    if (value) {
      //this.render();
      this.eventHandler();
    }
  }

  get campaign() {
    return this._campaign;
  }


  static get observedAttributes() { return ['src', 'controls']; }
  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'src' && (oldValue !== newValue)) {
        this.src = newValue;
      }
      if (name === 'controls' && (oldValue !== newValue)) {
        this.controls = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.$video = null;
      this.$player = null;

      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      this.$video = null;
      this.$player = null;

    } catch (error) {
      console.log(error);
    }
  }


  render() {
    try {
      console.log('render');

      this.innerHTML = `<video id="sbs-player-ad-video-${SbsPlayerGlobal.id.uuid}" ${this.controls ? 'controls' : ''} 
      webkit-playsinline playsinline oncontextmenu="return false;" src="${this.src}" width="100%">
        <p>Your browser doesn't support HTML5 video.</p>
      </video>`;
      this.events();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      // const isFullScreenMode = document.fullscreenElement !== null;
      // if (isFullScreenMode) {
      //   this.querySelector('video').style.height = '100vh';
      // } else {
      //   this.querySelector('video').style.height = '100%';
      // }
    } catch (error) {
      console.error(error);
    }
  }

  event(event) {
    try {
      SbsPlayerGlobal.events.emitEvent('video-change-ad', event);
    } catch (error) {
      console.error(error);
    }
  }

  events() {
    try {
      console.log('events');
      if (typeof Hls === 'undefined' && getExt(this.src) === 'm3u8') {
        console.warn(`Hls is undefined', ${SbsPlayerGlobal.url.lib.hls}`);
        importScript(SbsPlayerGlobal.url.lib.hls, () => {
          this.initPlayer();
        });
      } else {
        this.initPlayer();
      }
    } catch (error) {
      console.log(error);
    }
  }

  initPlayer() {
    try {
      console.log('initPlayer');
      this.$video = this.querySelector('video');
      this.$player = null;

      if (getExt(this.$video.src) === 'm3u8') {
        this.$player = new Hls({
          debug: SbsPlayerGlobal.developement,
          enableWorker: true,
          lowLatencyMode: true,
          backBufferLength: 60 * 1.5,
        });
        this.loadSource(this.$video.src);
      } else {
        this.$player = this.$video;
      }
    } catch (error) {
      console.log(error);
    }

  }

  loadSource(media) {
    try {
      console.log('loadSource', media);

      let mediaUrl = media.replace('http://', 'https://');
      this.$video.src = mediaUrl
      if (getExt(this.$video.src) === 'm3u8') {
        this.$player.loadSource(mediaUrl);
        this.$player.attachMedia(this.$video);
      } else {
        this.$player = this.$video;
      }

      console.log('canplay ad $video volume: ' + this.$video.volume);
      let promise = this.$video.play();
      if (promise !== undefined) {
        promise.then(_ => {
          console.log('ad $video play');
          let volume = loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume);
          if (!volume) volume = 0.5;
          this.$video.volume = clamp(volume, 0, 1);
        }).catch(error => {
          console.warn('ad $video play error', error);
          SbsPlayerGlobal.events.emitEvent('autoplay-failed');
          this.$video.volume = 0;
          this.$video.play();
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  eventHandler() {
    try {
      console.log('eventHandler');
      //.style.transition = 'all 0.3s ease-in';
      this.style.opacity = 0;

      const self = this;
      // loadstart
      // durationchange
      // loadedmetadata
      // loadeddata
      // progress
      // canplay
      // canplaythrough

      const advertisement = this.campaign.ads[0] || this.campaign.ads || null;
      let trackingList = ['impression', 'start',
        'firstQuartile', 'mid_point', 'thirdQuartile',
        'complete', 'skip', 'click', 'fifteenSeconds', 'thirtySeconds', 'error'];
      let isPassedTracking = (item) => {
        return trackingList.indexOf(item) === -1 ? false : true;
      };
      let removeTrackingList = (item) => {
        for (var i = trackingList.length - 1; i >= 0; i--) {
          if (trackingList[i] === item) {
            trackingList.splice(i, 1);
          }
        }
      };
      if (getExt(this.$video.src) === 'm3u8') {
        this.$player.on(Hls.Events.MANIFEST_PARSED, function () {
          this.$video.play();
        });
      }
      this.$video.addEventListener('abort', (event) => {
      });
      this.$video.addEventListener('canplay', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('canplaythrough', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('durationchange', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('emptied', (event) => {
        //self.event(event);
      });
      this.$video.addEventListener('ended', async (event) => {
        self.src = '';
        self.event(event);
        if (isPassedTracking('complete')) {
          removeTrackingList('complete');
          await fetch(advertisement.tracking_url.complete);
        }
      });
      this.$video.addEventListener('error', async (event) => {
        self.event(event);
        if (trackingList.indexOf('error')) {
          removeTrackingList(trackingList, 'error');
          await fetch(advertisement.tracking_url.error);
        }
      });
      this.$video.addEventListener('loadeddata', async (event) => {
        self.event(event);
        let volume = loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume);
        if (!volume) volume = 0.5;
        this.$video.volume = clamp(volume, 0, 1);

        this.style.opacity = 1;

        if (isPassedTracking('impression')) {
          removeTrackingList('impression');
          await fetch(advertisement.tracking_url.impression);
        }
      });
      this.$video.addEventListener('loadedmetadata', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('loadstart', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('play', async (event) => {
        self.event(event);
        if (isPassedTracking('start')) {
          removeTrackingList('start');
          await fetch(advertisement.tracking_url.start);
        }
      });
      this.$video.addEventListener('playing', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('pause', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('progress', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('ratechange', (event) => {
      });
      this.$video.addEventListener('seeked', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('seeking', (event) => {
      });
      this.$video.addEventListener('stalled', (event) => {
      });
      this.$video.addEventListener('suspend', (event) => {
      });
      this.$video.addEventListener('timeupdate', async (event) => {
        self.event(event);

        let duration = event.currentTarget.duration;
        let currentTime = event.currentTarget.currentTime;

        let quarter = duration / 4;
        if ((quarter * 1) < currentTime && currentTime < (quarter * 2)) {
          if (isPassedTracking('firstQuartile')) {
            removeTrackingList('firstQuartile');
            await fetch(advertisement.tracking_url.firstQuartile);
          }
        }
        if ((quarter * 2) < currentTime && currentTime < (quarter * 3)) {
          if (isPassedTracking('mid_point')) {
            removeTrackingList('mid_point');
            await fetch(advertisement.tracking_url.mid_point);
          }
        }
        if ((quarter * 3) < currentTime && currentTime < duration) {
          if (isPassedTracking('thirdQuartile')) {
            removeTrackingList('thirdQuartile');
            await fetch(advertisement.tracking_url.thirdQuartile);
          }
        }
        if (currentTime >= 15) {
          if (isPassedTracking('fifteenSeconds')) {
            removeTrackingList('fifteenSeconds');
            await fetch(advertisement.tracking_url.fifteenSeconds);
          }
        }
        if (currentTime >= 30) {
          if (isPassedTracking('thirtySeconds')) {
            removeTrackingList('thirtySeconds');
            await fetch(advertisement.tracking_url.thirtySeconds);
          }
        }
      });
      this.$video.addEventListener('volumechange', (event) => {
        self.event(event);
      });
      this.$video.addEventListener('waiting', (event) => {
        self.event(event);
      });

      setTimeout(() => {
        if (this.$video.paused) this.$video.play();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }
});